import { Affix, Button, Col, Row } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { CategorySelector } from '../../../shelvesBase';
import styles from './index.less';

export const StepOne = observer((props: { stepOneStore; }) => {
  const { onSave, categoryStore } = props.stepOneStore;
  const { canNext } = categoryStore;
  return (
    <div
      className={styles.stepOneWrap}
    >
      <CategorySelector store={categoryStore}/>
      <Affix offsetBottom={0}>
        <div className={styles.footerWrap}>
          <Row>
            <Col
              className={styles.Wrap}
              span={4}
            />
            <Col
              className={styles.footer}
              span={20}
            >
              <Button
                disabled={!canNext}
                onClick={() => {
                  onSave('next');
                }}
                type="primary"
              >
                下一步
              </Button>
            </Col>
          </Row>
        </div>
      </Affix>
    </div>
  );
});
