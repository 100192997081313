import { Spin } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { PublishResult, StepsComponent } from '../shelvesBase';
import styles from './index.less';
import Store from './store';

const store = new Store();

@observer
export default class ShelvesNew extends Component {
  render(): JSX.Element {
    const { loading, currentStep, platformType, DOUYIN_STEPS, listingTimeMode, shopName, gmsGoodsId, visibleSubmitResult, closeModal, closeResultModal, submitResult, errorMessage, detailId } = store;
    const steps = DOUYIN_STEPS;
    return (
      <div
        className={styles.contentWrap}
        id="fullModalWrap"
      >
        <Spin spinning={loading}>
        
          <StepsComponent currentStep={currentStep}/>
          <div
            className={styles.stepContent}
          >
            {steps[currentStep].content}
          </div>
        </Spin>
        <PublishResult
          closeModal={closeModal}
          closeResultModal={closeResultModal}
          errorMessage={errorMessage}
          goodsId={detailId}
          listingTimeMode={listingTimeMode}
          platformId={platformType}
          shopName={shopName}
          submitResult={submitResult}
          visibleSubmitResult={visibleSubmitResult}
        />
      </div>
    );
  }
}
