// 发货模式对应value
export const rulesValueDict = {
  normalRule: {
    name: '现货发货模式',
    presellType: 0,
    presellConfigLevel: 0,
  },
  productPresellRule: {
    name: '全款预售发货模式',
    presellType: 1,
    presellConfigLevel: 0,
  },
  stepRule: {
    name: '阶梯发货模式',
    presellType: 2,
    presellConfigLevel: 0,
  },
  timeSkuPresellWithNormalRule: {
    name: '现货+预售发货模式',
    presellType: 1,
    presellConfigLevel: 2,
  }, // 现货+预售
  timeSkuPurePresellRule: {
    name: '新预售发货模式',
    presellType: 1,
    presellConfigLevel: 3,
  }, // 新预售
};
