import { observer } from 'mobx-react';
import React from 'react';
import MainPictures from '../mainImgs/sortList/sortLists';
import styles from './index.less';
import { Input } from 'antd';

export const ProductInfo = observer((props) => {
  const { store, store: { imgs, onSortEnd, details, setDetails }, productImgTip, introduceEnable = false } = props;
  
  return (
    <>
      {introduceEnable && (
        <div className={styles.introWrap}>
          <Input.TextArea
            autoSize={{
              minRows: 3,
              maxRows: 100,
            }}
            maxLength={2000}
            onChange={(e) => {
              setDetails(e.target.value);
            }}
            placeholder="商品详细介绍,限2000字"
            showCount={{ formatter: ({ count, maxLength }) => `${count}/${maxLength}` }}
            value={details}
          />
        </div>
       
      )}
   
      <div
        className={styles.productInfoWrap}
        id="productInfo"
      >
        <h4>
          效果预览
        </h4>
        <div className={styles.productInfoImgsWrap}>
          <div className={styles.previewWrap}>
            <div>
              {details}
            </div>
            {
              imgs && imgs.length > 0 && imgs.map((item) => {
                return (
                  <div
                    className={styles.previewImgCom}
                    key={item.imgId}
                  >
                    <img
                      alt=""
                      className={styles.previewImg}
                      src={item.url}
                    />
                  </div>
                );
              })
            }
          </div>
          <div className={styles.uploadImgWrap}>
            <h4>
              详情编辑
            </h4>
            <p>
              支持上传多张图片，拖拽可调整顺序
              {productImgTip}
            </p>
            <div className={styles.productImgsWrap}>
              <MainPictures
                axis="xy"
                helperClass={styles.sortableHelper}
                items={imgs}
                onSortEnd={onSortEnd}
                store={store}
              />
            </div>
          </div>
        </div>
      </div>
    </>

  );
});

