import React from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Row, Col, Popover } from 'antd';
import { observer } from 'mobx-react';
import type SingleUploadImgStore from '../singleUploadImg/store';
import styles from './index.less';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { SingleUploadImg } from '../../../../shelvesBase';
import deleteIcon from '../../../../../assets/images/deleteIcon.png';

const ReportTips = () => {
  return (
    <div>
      <div className={styles.tipsTitle}>
        上传需求
      </div>
      <div>
        需为近一年由第三方权威质检机构出具的一份还有CMA或CNAS认证的质检报告。
      </div>
      <div>
        ——检测报告里的商品名称，品牌，生产商名称等信息与商品详情发布的的商品信息需一致
      </div>
      <div>
        ——如报告无品牌名称，需提供品牌名称、商品名称及生产单位的产品实拍图
      </div>
    </div>
  );
};

export const ProductQuality: React.FC<{ store: SingleUploadImgStore; }> = observer((props) => {
  const { qualityReportList } = props.store;
  return (
    <Row
      style={{ marginBottom: qualityReportList.length === 0 ? 12 : 0 }}
    >
      <Col
        className={styles.label}
        span={3}
      >
        质检报告：
      </Col>
      <Col
        className={styles.content}
        span={21}
      >
        <Popover
          content={(<ReportTips/>)}
          placement="right"
        >
          <QuestionCircleOutlined className={styles.icon}/>
        </Popover>
        <span className={styles.tips}>
          （最多上传20张）
        </span>
      </Col>
      <SortableList
        axis="xy"
        helperClass={styles.sortableHelper}
        onSortEnd={props.store.onSortEnd}
        qualityReportList={qualityReportList}
        store={props.store}
      />
    </Row>
  );
});

// 图片展示卡片
const SortableItem = SortableElement(({ src, store, id }) => {
  const { deleteImg } = store;
  return (
    <div
      className="publishGoodsPicture"
      style={{ marginBottom: 12 }}
    >
      <img
        alt=""
        className="img"
        src={src}
      />
      <img
        className="delete"
        onMouseDown={(e) => {
          deleteImg('qualityReport', id);
          e.stopPropagation();
        }}
        src={deleteIcon}
      />
    </div>
  );
});

const SortableList = SortableContainer(observer(({ qualityReportList, store }) => {
  return (
    <div className={styles.imgWrapper}>
      {
        Array.isArray(qualityReportList) && qualityReportList.length > 0 ? qualityReportList.map((item, index) => (
          <SortableItem
            id={item.id}
            index={index}
            key={`item-${item.id}`}
            src={item.url}
            store={store}
          />
        )) : null
      }
      {
        qualityReportList?.length < 20 ? (
          <SingleUploadImg
            deleteImg={store.deleteImg}
            fileList={[]}
            selectImg={store.selectImg}
            type="qualityReport"
          />
        ) : null
      }
    </div>
  );
}));
