import { Input, Checkbox, Row, Col } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import styles from './index.less';

export const TimeVarSelect = observer((props: { sizeColorStore; }) => {
  const { hasTimeVar } = props.sizeColorStore;
  return (
    <Row>
      <Col
        className={styles.colContent}
        span={5}
      >
        <span className={styles.requiredTip}>
          *
        </span>
        发货时效
        <span className={styles.colMao}>
          :
        </span>
      </Col>
      <Col span={19}>
        {
          hasTimeVar === 2 && <NormalPresell store={props.sizeColorStore}/>
        }
        {
          hasTimeVar === 3 && <PurePresell store={props.sizeColorStore}/>
        }
      </Col>
    </Row>
  );
});

// 现货+预售
const NormalPresell = observer((props: { store; }) => {
  const { timeSkuSpecDetialOptions, selectTime, timeSelectList } = props.store;
  return (
    <div className={styles.timerVarWrapper}>
      {
        timeSkuSpecDetialOptions.length > 0 && timeSkuSpecDetialOptions.map((item) => (
          <div
            className={styles.eachTimeVar}
            key={item}
          >
            <Checkbox
              checked={item?.includes('48小时') || timeSelectList?.includes(item)}
              disabled={item?.includes('48小时')}
              key={item}
              onClick={(e) => {
                selectTime(e, item);
              }}
            />
            <span className={styles.checkboxText}>
              {item}
            </span>
          </div>
        ))
      }
    </div>
  );
});

// 新预售
const PurePresell = observer((props: { store; }) => {
  const { timeSkuPurePresellDetailOptions, selectTime, timeSelectList } = props.store;
  return (
    <div className={styles.timerVarWrapper}>
      {
        timeSkuPurePresellDetailOptions.length > 0 && timeSkuPurePresellDetailOptions.map((item) => (
          <div
            className={styles.eachTimeVar}
            key={item}
          >
            <Checkbox
              checked={timeSelectList?.includes(item)}
              onClick={(e) => {
                selectTime(e, item);
              }}
            />
            <span className={styles.checkboxText}>
              {item}
            </span>
          </div>
        ))
      }
    </div>
  );
});
