
import type { FormInstance } from 'antd';
import type { BaseData } from 'egenie-utils';
import { request } from 'egenie-utils';
import { observable, action } from 'mobx';
import { nanoid } from 'nanoid';
import React from 'react';
import { api, scrollToAnchor } from '../../utils';
import { getImageInfo, getImageUrl, dealUnlegalImg, CategorySelectorStore, MainImgsStore, ProductInfoStore, getVideoInfo } from '../shelvesBase';
import { StepOne } from './douyinInfo/stepOne';
import StepTwo from './douyinInfo/stepTwo';
import AnchorStore from './douyinInfo/stepTwo/anchor/store';
import BaseinfoStore from './douyinInfo/stepTwo/baseinfo/store';
import { rulesValueDict } from './douyinInfo/stepTwo/pageFormInfo/constant';
import PageFormInfoStore from './douyinInfo/stepTwo/pageFormInfo/store';
import { SizeColorStore } from './douyinInfo/stepTwo/saleInfo/sizeColorStore';
import SingleUploadImgStore from './douyinInfo/stepTwo/singleUploadImg/store';
import StepTwoStore from './douyinInfo/stepTwo/store';
import type { DouyinData, ICatAndDeliveryTemplates, IObj, IUpdateRules } from './interface';

class Store {
  constructor() {
    const params = new URLSearchParams(window.location.href.split('?')[1]);
    this.gmsGoodsId = params.get('gmsGoodsId');
    this.shopId = params.get('shopId');
    this.platformType = Number(params.get('platformType'));
    this.pageId = params.get('pageId');
    this.getAllShops();
    this.getPosGoodsGroupPics();
    this.initData();
  }

  @observable public parent;

  @observable public pageId;

  @observable public callback;

  @observable public loading = false;

  @observable public visibleSubmitResult = false;

  @observable public detailId = '';

  @observable public shopName = '';

  @observable public platformType;

  @observable public stepTwoStore = new StepTwoStore(this);

  @observable public categoryStore = new CategorySelectorStore();

  @observable public mainImgsStore = new MainImgsStore();

  @observable public baseinfoStore = new BaseinfoStore(this);

  @observable public anchorStore = new AnchorStore(this);

  @observable public sizeColorStore = new SizeColorStore(this);

  @observable public singleUploadImgStore = new SingleUploadImgStore(this);

  @observable public productInfoStore = new ProductInfoStore(this);

  @observable public pageFormInfoStore = new PageFormInfoStore(this);

  @observable public serviceFormRef = React.createRef<FormInstance>();

  @observable public shopId: string;

  @observable public gmsGoodsId: string;
  
  @observable public currentStep = 0;

  @observable public goodsPropsValuesTmp: IObj;

  @observable public listingTimeMode = 0; // 上架时间 0 ： 立即上架 2: 放入仓库

  @observable public vendorName = ''; // 档口号

  @observable public sellNo = ''; // 货号

  @observable public categoryId: string | number;// 选中类目id

  @observable public categoryName = '';// 选中类目名称

  @observable public categoryIds = '';

  @observable public DOUYIN_STEPS = [
    {
      title: '选择类目/品牌',
      content: (
        <StepOne stepOneStore={this}/>
      ),
    },
    {
      title: '完善商品信息',
      content: (
        <StepTwo
          anchorStore={this.anchorStore}
          baseinfoStore={this.baseinfoStore}
          mainImgsStore={this.mainImgsStore}
          pageFormInfoStore={this.pageFormInfoStore}
          productInfoStore={this.productInfoStore}
          singleUploadImgStore={this.singleUploadImgStore}
          sizeColorStore={this.sizeColorStore}
          stepTwoStore={this.stepTwoStore}
          store={this}
        />
      ),
    },
  ];

  @observable public visible = false;

  @observable public visiblePermission = false;

  @observable public authBtnLoading = false;

  @observable public submitResult = 'init';

  @observable public errorMessage = '';

  @observable public platformName = '抖音';

  @action public initData = async() => {
    this.loading = true;
    try {
      await this.categoryStore.initData(this.gmsGoodsId, this.platformType, this.shopId);
    } catch (e) {
      console.log(e);
    } finally {
      this.loading = false;
    }
  };
  
  @action public gotoAuth = () => {
    this.authBtnLoading = true;
    request<BaseData<string>>({ url: `${api.gotoAuth}/${this.shopId}` }).then((res) => {
      this.authBtnLoading = false;
      window.open(res.data);
      this.visiblePermission = false;
    })
      .catch(() => this.authBtnLoading = false);
  };

  @action public getDouyinInfo = (categoryId) => {
    this.loading = true;
    request<BaseData<DouyinData>>({
      url: api.getDouyinGoodsPutOnShelfDetail,
      method: 'post',
      data: {
        gmsGoodsId: Number(this.gmsGoodsId),
        categoryId,
      },
    }).then(async(res) => {
      this.next();
      const {
        goodsProps,
        platformExtends,
        goodsBase,
        vendorSimple,
        saleProps,
        goodsSkuList,
        goodsPropPicList,
        goodsPic,
      } = res.data;
  
      const allProps = goodsProps || [];
      const { allProductProps, requiredProps } = this.dealData(allProps);
      this.anchorStore.baseinfoDyamicProps = requiredProps;
      const allTitles = this.anchorStore.allTitles;

      // 计算基本信息应填数量
      allTitles.baseinfo.requiredNum = 2;
      allTitles.baseinfo.requiredNum += requiredProps.length;
      this.anchorStore.allTitles = allTitles;

      // 回写商品属性
      this.baseinfoStore.goodsProps = allProductProps;
      const { sizeInfoTemplateId, qualityInspectionReports } = platformExtends;
      const defaultSizeId = this.baseinfoStore.sizeTemplateOptions[0] ? this.baseinfoStore.sizeTemplateOptions[0].value : null;

      // 回写商品标题，推荐语，支付方式
      this.baseinfoStore.writeBackBaseinfo(goodsProps, goodsBase?.goodsName || '', platformExtends?.recommendRemark || '', platformExtends?.payType || 1, sizeInfoTemplateId || defaultSizeId || '');
      const goodsProp = goodsProps.find((item) => item.name === '货号');
      if (goodsProp) {
        this.sellNo = goodsProp.valueName || '';
      }

      //  回写材质成分
      if (res.data.productMaterialPropsVo) {
        this.baseinfoStore.hasMaterialProps = true;
        Object.assign(this.baseinfoStore.materialStore, {
          materialDict: res.data.productMaterialPropsVo?.materialPropOptions || [],
          materialDictNoInput: res.data.productMaterialPropsVo.noNeedContentPropNames || [],
          isMaterialRequired: res.data.productMaterialPropsVo.required,
        });
        
        this.baseinfoStore.materialStore.writebackMaterialProps(res.data.productMaterialPropsVo?.materialPropValus || []);
        this.anchorStore.allTitles.baseinfo.requiredNum++;
      }
      try {
        // 处理主图尺寸
        for (let i = 0; i < goodsPic.mainPics.length; i++) {
          goodsPic.mainPics[i] = await this.dealMainImg(goodsPic.mainPics[i]);
        }
      } catch (e) {
        console.log('处理主图尺寸错误', e);
      }

      // 回写质检报告
      if (Array.isArray(qualityInspectionReports)) {
        this.singleUploadImgStore.qualityReportList = qualityInspectionReports.slice(0, 20).map((item, index) => ({
          url: item,
          key: index,
        }));
      }

      // 回写主图
      this.mainImgsStore.writeBackMainImgs(goodsPic);

      // 处理主图视频
      if (goodsPic.videoManageId) {
        try {
          const videoInfo = await getVideoInfo(goodsPic.videoManageId);
          const { duration, size, format } = videoInfo;

          const { width, height } = JSON.parse(size);

          // 判断主图视频符合要求
          const validVideo = duration < 60 && width / height === 1 && format === 'video/mp4';

          // 主图视频符合要求才填充
          if (validVideo) {
            this.singleUploadImgStore.videoFileList = [
              {
                url: goodsPic.videoUrl,
                id: goodsPic.videoManageId,
              },
            ];
          }
        } catch (e) {
          console.log('处理主图视频错误', e);
        }
      }

      // 处理详情图格式
      try {
        for (let i = 0; i < goodsPic.detailPics.length; i++) {
          const { Format } = await getImageInfo(goodsPic.detailPics[i]);
          goodsPic.detailPics[i] = dealUnlegalImg(goodsPic.detailPics[i], Format.value);
        }
      } catch (e) {
        console.log('处理详情图格式错误', e);
      }

      // 回写详情图
      this.productInfoStore.writeBackDetailImgs(goodsPic);

      // 回写发货模式
      const { presellType, presellConfigLevel } = platformExtends;
      const selectedDeliveryMode = this.pageFormInfoStore.deliveryModesList.find((item) => {
        return rulesValueDict[item].presellType === presellType && rulesValueDict[item].presellConfigLevel === presellConfigLevel;
      });

      Object.assign(platformExtends, { deliveryMode: selectedDeliveryMode || 'normalRule' });

      this.stepTwoStore.writeBackOtherInfo(platformExtends || {});

      const hasTimeVar = presellConfigLevel;

      // 处理颜色图片
      try {
        for (let i = 0; i < goodsPropPicList.length; i++) {
          goodsPropPicList[i].url = await this.dealColorImg(goodsPropPicList[i].url);
        }
      } catch (e) {
        console.log('处理颜色图片出错', e);
      }

      // 初始化颜色尺码
      this.sizeColorStore.initData(saleProps, goodsPropPicList, goodsSkuList, hasTimeVar);
    })
      .finally(() => {
        this.loading = false;
      });
  };

  // 获取发货模式
  @action public getProductRules = (categoryId): Promise<void> => {
    return request<BaseData<IUpdateRules>>({
      // url: api.getUpdateRule,
      url: '/api/gms/goods/platform/douyin/getProductUpdateRule',
      method: 'POST',
      data: {
        categoryId,
        shopId: this.shopId,
      },
    }).then((res) => {
      const rulesData = res.data;

      // 处理发货模式
      this.pageFormInfoStore.deliveryModesList = Object.keys(rulesData.fulfillmentRuleVo).filter((item) => {
        return item !== 'skuPresellRule' && rulesData.fulfillmentRuleVo[item].support;// sku预售发货模式默认不显示
      });
      if (rulesData.afterSaleRuleVo?.supplyDayReturnRule?.enable) {
        this.pageFormInfoStore.afterSaleEnabled = true;
        this.pageFormInfoStore.afterSaleOptions = this.mapOptions(rulesData.afterSaleRuleVo.supplyDayReturnRule.options, 'name', 'value');
      }
      this.sizeColorStore.timeSkuSpecDetialOptions = rulesData?.fulfillmentRuleVo?.timeSkuPresellWithNormalRule?.timeSkuSpecDetial.map((item) => item.specValue);// 现货+预售
      this.sizeColorStore.timeSkuPurePresellDetailOptions = rulesData?.fulfillmentRuleVo?.timeSkuPurePresellRule?.timeSkuSpecDetial.map((item) => item.specValue);// 新预售
    });
  };

  @action public dealData = (goodsProps): any => {
    if (!goodsProps || goodsProps.length === 0) {
      return {
        allProductProps: [],
        requiredProps: [],
      };
    }
    const requiredProps = goodsProps.filter((el) => el.required);
    const noRequiredProps = goodsProps.filter((el) => !el.required);
    return {
      requiredProps,
      allProductProps: requiredProps.concat(noRequiredProps),
    };
  };

  @action public getPosGoodsGroupPics = (): void => {
    request<BaseData<any>>({
      url: api.getPosGoodsGroupPics,
      method: 'post',
      data: { gmsGoodsId: this.gmsGoodsId },
    }).then(async(res) => {
      this.mainImgsStore.styleImgLists = this.dealMainPics(res?.data || []);

      // 处理款式库中的图片尺寸
      for (let i = 0; i < this.mainImgsStore.styleImgLists.length; i++) {
        this.mainImgsStore.styleImgLists[i].url = await this.dealMainImg(this.mainImgsStore.styleImgLists[i].url);
      }
    })
      .catch(() => {
        this.mainImgsStore.styleImgLists = [];
      });
  };

  @action public dealMainPics = (mainPicUrls): any => {
    if (mainPicUrls.length === 0) {
      return [];
    }
    let arr = [];
    mainPicUrls.forEach((item) => {
      arr = [
        ...arr,
        ...item.mainPicUrls,
      ];
    });
    return arr.map((url) => {
      return {
        id: nanoid(),
        imgId: nanoid(),
        url,
      };
    });
  };

  @action public next = (): void => {
    this.currentStep += 1;
    scrollToAnchor('stepHeader');
  };

  @action public submitAgain = (): void => {
    this.currentStep = 0;
    scrollToAnchor('stepHeader');
  };

  @action public getAllShops = (): void => {
    request<BaseData<ICatAndDeliveryTemplates[]>>({
      url: api.getDouyinFreightTemplateList,
      method: 'post',
      data: { shopId: this.shopId },
    }).then((res) => {
      this.pageFormInfoStore.freightTemplateList = this.mapOptions(res.data || [], 'templateName', 'id');
    })
      .catch(() => {
        this.pageFormInfoStore.freightTemplateList = [
          {
            label: '包邮',
            value: 0,
          },
        ];
      });
  };

  private mapOptions = (arr, label, value) => {
    return arr.map((item) => ({
      label: item[label],
      value: item[value],
    }));
  };

  @action public closeModal = () => {
    window.top.egenie.closeTab(this.pageId);
  };

  @action public closeResultModal = () => {
    this.visibleSubmitResult = false;
  };

  // 选择类目后下一步
  @action public onSave = async() => {
    this.loading = true;

    const categoryIds = this.categoryStore.selectedValue;
    const categoryId = categoryIds[categoryIds.length - 1];
    this.categoryId = categoryId;
    this.categoryIds = categoryIds.join(',');
    this.categoryName = this.categoryStore.selectTxt;

    await this.getProductRules(categoryId);

    // 获取尺码模板
    try {
      const sizeRes = await request<BaseData<[]>>({
        url: '/api/gms/goods/platform/douyin/componentTemplateList',
        method: 'POST',
        data: {
          shopId: this.shopId,
          rootCategoryId: categoryIds[0],
          leafCategoryId: categoryId,
        },
      });
      const sizeOptions = this.mapOptions(sizeRes.data, 'templateName', 'templateId');
      this.baseinfoStore.sizeTemplateOptions = sizeOptions;
    } catch (e) {
      console.log('获取尺码模板出错', e);
    }
    this.getDouyinInfo(categoryId);
  };

  public disposeData = (obj) => {
    return Object.keys(obj)
      .map((item) => {
        return {
          label: obj[item],
          value: item,
        };
      });
  };

  // 处理主图尺寸
  public dealMainImg = async(url) => {
    const { ImageWidth, ImageHeight, Format } = await getImageInfo(url);
    
    // 处理主图（最小宽度600，比例1:1）
    const resizeWidth = Number(ImageWidth.value) < 600 ? 600 : Number(ImageWidth.value);
    const resizeHeight = resizeWidth;
    
    let newUrl = getImageUrl(url, resizeWidth, resizeHeight, ImageWidth, ImageHeight);
   
    newUrl = dealUnlegalImg(newUrl, Format.value);
    return newUrl;
  };

  // 处理颜色图尺寸
  private dealColorImg = async(url) => {
    const { ImageWidth, ImageHeight, Format } = await getImageInfo(url);
    
    // 处理颜色图尺寸 比例为1:1
    const resizeWidth = Math.min(Number(ImageWidth.value), Number(ImageHeight.value));
    const resizeHeight = resizeWidth;
    
    let newUrl = getImageUrl(url, resizeWidth, resizeHeight, ImageWidth, ImageHeight);
   
    newUrl = dealUnlegalImg(newUrl, Format.value);
    return newUrl;
  };
}

export default Store;

