import React from 'react';
import { PUBLIC_IMAGE_URL } from '../../../../utils';
import styles from './index.less';

// 添加图标
export const AddIcon: React.FC<{ onClick: () => void; }> = (props) => {
  return (
    <div
      className={styles.addIcon}
      onClick={props.onClick}
    >
      <div>
        +
      </div>
      <div>
        添加
      </div>
    </div>
  );
};

interface IExpandCollapseButton{
  flag: boolean;
  name: Name;
  handleClick: (flag: boolean) => void;
}

type Name = '颜色' | '尺码';

/**
   * 展开收起按钮组件
   * @param flag true为展开 false为收起(选项状态)
   * @param name 颜色或者尺码
   * @param handleClick 处理展开收起
  */
export const ExpandCollapseButton: React.FC<IExpandCollapseButton> = (props) => {
  const { name, flag, handleClick } = props;
  return (
    <div className={styles.expandButtonWrapper}>
      <div
        className={styles.expandButton}
        onClick={() => {
          handleClick(!flag);
        }}
      >
        <span className={styles.expandText}>
          {flag ? '收起' : `查看全部${name}`}
        </span>
        <img
          className={styles.expandIcon}
          src={`${PUBLIC_IMAGE_URL}${flag ? 'collapse.png' : 'expand.png'}`}
        />
      </div>
    </div>
  );
};
