import { Input, InputNumber } from 'antd';
import { Observer } from 'mobx-react';
import React from 'react';
import styles from './index.less';

// 商品规格表格列
export const saleSpecColumns = (context) => {
  const { colorPropName, sizePropName } = context;
  return [
    {
      name: '颜色',
      key: 'color',
      width: 100,
    },
    {
      name: '尺码',
      key: 'size',
      width: 100,
    },
    {
      name: '进货价',
      key: 'costPrice',
      width: 100,
    },
    {
      name: '时效规格',
      key: 'timeVar',
      width: 140,
      ejlHidden: false,
    },
    {
      name: (
        <Observer>
          {
            () => {
              return (
                <div className={styles.gridInputWrapper}>
                  <span className={styles.requiredTip}>
                    *
                  </span>
                  售价
                  <InputNumber
                    className={`${styles.gridHeaderInput} ${styles.gridInput}`}
                    max={999999}
                    min={1}
                    onBlur={() => {
                      context.handleAllSalePriceBlur();
                    }}
                    onChange={(price) => {
                      context.handleAllSalePriceChange(price);
                    }}
                    onPressEnter={() => {
                      context.handleAllSalePriceBlur();
                    }}
                    placeholder="批量填写"
                    precision={2}
                    size="small"
                    value={context.allPrice}
                  />
                </div>
              );
            }
          }
        
        </Observer>
      ),
      width: 160,
      key: 'salePrice',
      formatter: ({ row }) => {
        return (
          <div className={styles.gridInputWrapper}>
            <InputNumber
              max={999999}
              min={1}
              onChange={(price) => {
                const goodsSpec = assembleProperty(colorPropName, row.color, sizePropName, row.size, row.timeVar);
                context.handleSingleSalePriceChange(goodsSpec, price);
              }}
              onClick={(e) => e.stopPropagation()}
              precision={2}
              size="small"
              value={row.salePrice}
            />
          </div>
        );
      },
    },
    {
      name: (
        <Observer>
          {
            () => {
              return (
                <div className={styles.gridHeader}>
                  <span className={styles.requiredTip}>
                    *
                  </span>
                  库存
                  <InputNumber
                    className={`${styles.gridHeaderInput} ${styles.gridInput}`}
                    max={999999}
                    min={0}
                    onBlur={() => {
                      context.handleAllNumBlur();
                    }}
                    onChange={(val) => {
                      context.handleAllNumChange(val);
                    }}
                    onPressEnter={() => {
                      context.handleAllNumBlur();
                    }}
                    placeholder="批量填写"
                    precision={0}
                    size="small"
                    value={context.allNum}
                  />
                </div>
              );
            }
          }
        </Observer>
      ),
      width: 160,
      key: 'stockNum',
      formatter: ({ row }) => {
        return (
          <div className={styles.gridInputWrapper}>
            <InputNumber
              max={999999}
              min={0}
              onChange={(price) => {
                const goodsSpec = assembleProperty(colorPropName, row.color, sizePropName, row.size, row.timeVar);
                context.handleSingleNumChange(goodsSpec, price);
              }}
              onClick={(e) => e.stopPropagation()}
              precision={0}
              size="small"
              value={row.stockNum}
            />
          </div>
        );
      },
    },
    {
      name: 'SKU编码',
      key: 'goodsSkuNo',
      formatter: ({ row }) => {
        return (
          <Input
            defaultValue={row.goodsSkuNo}
            onBlur={(e) => {
              const goodsSpec = assembleProperty(colorPropName, row.color, sizePropName, row.size, row.timeVar);
              context.handleSkuChange(goodsSpec, e.target.value);
            }}
            onClick={(e) => e.stopPropagation()}
          />
        );
      },
    },
  ];
};

const assembleProperty = (colorPropName, color, sizePropName, size, timeVar) => {
  return `${colorPropName}:${ color };${sizePropName}:${ size}` + `${timeVar ? `;发货时效:${timeVar}` : '' }`;
};
