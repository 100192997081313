import { Anchor } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import styles from './index.less';

const { Link } = Anchor;

export const AnchorCustom = observer((props) => {
  const { allTitles, changeAnchor, currentLink, isValid } = props.store;
  const allTitlesLabels = Object.keys(allTitles);
  return (

    <div className={styles.anchorWrap}>
      <Anchor
        onChange={changeAnchor}
        showInkInFixed={false}
      >
      
        {allTitlesLabels.map((item) => {
          const { id, title, requiredNum, hasFilled } = allTitles[item];
          return (
            <div
              className="animate__animated animate__fadeIn"
              key={id}
              style={{ borderLeft: currentLink.indexOf(id) > -1 ? '2px solid #1978ff' : '' }}
            >
              <Link
                href={`#${id}`}
                title={title}
              >
                {
                  isValid === 'init' ? '' : (
                    hasFilled >= requiredNum ? '已完成' : (
                      <div>
                        必填项
                        <span className={styles.noFillNum}>
                          {' '}
                          {requiredNum - hasFilled}
                          {' '}
                        </span>
                        项未填
                      </div>
                    )
                  )
                }
              </Link>
            </div>
          );
        })}
      </Anchor>
    </div>
  );
});

