import { Cascader, Form, Input, message, Modal } from 'antd';
import type { BaseData } from 'egenie-common';
import { request } from 'egenie-common';
import { action, observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { api } from '../api';
import type { UploadStore } from '../uploadStore';
import styles from './index.less';

interface IResponse {
  status?: string;
  msg?: string;
  info?: string;
  data?: number | string;
  code?: number | string;
}

class CreateFolderStore {
  constructor(options: { parent: UploadStore; }) {
    this.parent = options.parent;
  }

  @observable public parent: UploadStore;

  @observable public visible = false;

  @action public handleOpen = () => {
    this.visible = true;
  };

  @action public onCancel = () => {
    this.visible = false;
  };

  @action public saveCreate = async(values) => {
    const {
      content,
      name,
    } = values;
    const { type } = this.parent;
    const res = await request<BaseData<IResponse>>({
      url: api[type].createFolder,
      method: 'post',
      data: {
        parentId: content[content.length - 1],
        name,
      },
    });
    if (res) {
      message.success('创建成功！');
      this.onCancel();
      await this.parent.getFolder();
      this.parent.uploadCatalogueId = content.concat([res.data]);
    }
  };
}

const CreateFolder: React.FC<{ store: CreateFolderStore; }> = observer(({ store }) => {
  const {
    visible,
    onCancel,
    saveCreate,
    parent: {
      catalogueList,
      uploadCatalogueId,
    },
  } = store;
  const [form] = Form.useForm();
  useEffect(() => {
    if (!visible) {
      form.resetFields();
    } else {
      form.setFieldsValue({ content: uploadCatalogueId });
    }
  }, [visible]);
  const onOk = () => {
    form.validateFields()
      .then((values) => {
        console.log(values, '校验表单');
        saveCreate(values);
      })
      .catch((errorInfo) => {
        console.log(errorInfo, '未全填写');
      });
  };

  return (
    <Modal
      centered
      className={styles.creatFolderWrap}
      maskClosable={false}
      onCancel={onCancel}
      onOk={onOk}
      open={visible}
      title="新建文件夹"
      width="444px"
    >
      <Form
        autoComplete="off"
        form={form}

        // initialValues={{ remember: true }}
        labelCol={{ span: 6 }}
        name="createFolder"
        wrapperCol={{ span: 16 }}
      >
        <Form.Item
          label="目录："
          name="content"
          rules={[
            {
              required: true,
              message: '请选择目录',
            },
          ]}
        >
          <Cascader
            changeOnSelect
            options={toJS(catalogueList)}
          />
        </Form.Item>

        <Form.Item
          label="文件夹名称："
          name="name"
          rules={[
            {
              required: true,
              message: '请输入名称',
            },
          ]}
        >
          <Input/>
        </Form.Item>
      </Form>
    </Modal>

  );
});

export { CreateFolder, CreateFolderStore };
