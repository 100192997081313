import { action, observable } from 'mobx';
import { scrollToAnchor, getStrLen } from '../../../../../utils';

export default class Store {
  constructor(options) {
    this.parent = options;
  }

  @observable public parent;

  @observable public isValid = 'init';

  @observable public baseinfoDyamicProps = [];

  @observable public allTitles = {
    baseinfo: {
      title: '基本信息',
      requiredNum: 2,
      hasFilled: 0,
      id: 'baseinfo',
    },
    goodsDec: {
      title: '图文信息',
      requiredNum: 2,
      hasFilled: 0,
      id: 'goodsDec',
    },
    saleInfo: {
      title: '价格库存',
      requiredNum: 3,
      hasFilled: 0,
      id: 'saleInfo',
    },
    productQuality: {
      title: '商品资质',
      requiredNum: 0,
      hasFilled: 0,
      id: 'productQuality',
    },
    afterSaleAndOther: {
      title: '服务与履约',
      requiredNum: 3,
      hasFilled: 0,
      id: 'afterSaleAndOther',
    },
  };

  @observable public currentLink = '';

  @observable public productValidate = true;

  public validMatertial = false;

  @action public changeAnchor = (currentLink) => {
    this.currentLink = currentLink;
  };

  @action public getIncomplete = async() => {
    const allTitles = this.allTitles;
    this.isValid = 'valid';
    this.productValidate = true;
 
    // 基本信息
    allTitles.baseinfo.hasFilled = 0;

    // 材质成校验
    if (this.parent.baseinfoStore.hasMaterialProps && (this.parent.baseinfoStore.materialStore.materialLists[0].value !== undefined || this.parent.baseinfoStore.materialStore.isMaterialRequired)) {
      this.validMatertial = true;
    }

    // 材质成分校验
    if (this.validMatertial && this.parent.baseinfoStore.materialStore.validMaterial()) {
      allTitles.baseinfo.hasFilled++;
    }
    
    try {
      await this.parent.baseinfoStore.baseinfoFormRef.current.validateFields();
    } catch (e) {
      console.log(e);

      this.productValidate = !(e.errorFields.some((item) => {
        return item.name.includes('goodsName');
      }));
    } finally {
      const baseInfo = this.parent.baseinfoStore.baseinfoFormRef.current.getFieldsValue();
      let baseInfoInComNum = 0;
      if (baseInfo.goodsName && this.productValidate) {
        baseInfoInComNum++;
      }
      const recommendRemark = baseInfo.recommendRemark || '';
      if (!recommendRemark || (recommendRemark && getStrLen(recommendRemark) > 15 && getStrLen(recommendRemark) < 101)) {
        baseInfoInComNum++;
      }
        
      this.baseinfoDyamicProps.length && this.baseinfoDyamicProps.forEach((item) => {
        if (baseInfo[item.id]) {
          baseInfoInComNum++;
        }
      });
      allTitles.baseinfo.hasFilled += baseInfoInComNum;
    }

    // 价格库存
    allTitles.saleInfo.requiredNum = this.parent.pageFormInfoStore.deliveryMode === 'normalRule' ? 6 : 6;
    try {
      await this.parent.pageFormInfoStore.deliveryInfoFormRef.current.validateFields();
      allTitles.saleInfo.hasFilled = allTitles.saleInfo.requiredNum;// 销售信息已校验过
    } catch (e) {
      const unCompleteNum = e.errorFields.length;
      const { requiredNum } = allTitles.saleInfo;
      allTitles.saleInfo.hasFilled = requiredNum - unCompleteNum;
      console.log(allTitles.saleInfo);
    }

    // 商品描述
    allTitles.goodsDec.hasFilled = 0;
    let goodsInfoInComNum = 0;
    if (this.parent.mainImgsStore.mainPics.length > 2) {
      goodsInfoInComNum++;
    }
    if (this.parent.productInfoStore.imgs.length > 0) {
      goodsInfoInComNum++;
    }
    allTitles.goodsDec.hasFilled = goodsInfoInComNum;

    // 售后 afterSaleAndOther
    allTitles.afterSaleAndOther.hasFilled = 0;
    const { serviceInfoFormRef } = this.parent.pageFormInfoStore;
  
    let errorFieldsLength = 0;
    try {
      await serviceInfoFormRef.current.validateFields();
    } catch (e) {
      console.log(e.errorFields);
      errorFieldsLength = e.errorFields.length;
    } finally {
      allTitles.afterSaleAndOther.hasFilled = allTitles.afterSaleAndOther.requiredNum - errorFieldsLength;
    }

    // this.scrollTo();
  };

  @action public scrollTo = () => {
    const item = Object.keys(this.allTitles);
    for (let i = 0; i < Object.keys(this.allTitles).length; i++) {
      if (this.allTitles[item[i]].hasFilled < this.allTitles[item[i]].requiredNum) {
        scrollToAnchor(this.allTitles[item[i]].id);
        break;
      }
    }
  };
}
