import { observer } from 'mobx-react';
import React from 'react';
import styles from './index.less';
import MainPictures from './sortList/sortLists';

export const MainImgs = observer((props) => {
  const { store, store: { onSortEnd, styleImgLists, onPictureClick, clearMainImgs, mainPics }, singleUploadImgStore: { selectImg, mainPicTip }} = props;
  return (
    <div className={styles.mainImgsWrap}>
      <div className={styles.tips}>
        {mainPicTip}
      </div>
      <div className={styles.uploadWrap}>
        <div
          className={styles.uploadBtn}
          onClick={() => {
            selectImg('mainPic');
          }}
        >
          +
          {' '}
          上传主图
        </div>
        <div
          className={styles.clearImgs}
          onClick={clearMainImgs}
        >
          清除
        </div>
      </div>
      <div className={styles.allMainImgs}>
        <MainPictures
          axis="xy"
          helperClass={styles.sortableHelper}
          items={mainPics}
          onSortEnd={onSortEnd}
          store={store}
        />
      </div>
      {
        styleImgLists?.length > 0 && (
          <div
            className={styles.tips}
            style={{ margin: '8px 0' }}
          >
            款式图片库(可从款式图片库里选择主图)
          </div>
        )
      }
      <div className={styles.styleImgListsWrap}>
        {
          styleImgLists && styleImgLists.length > 0 ? (
            styleImgLists.map((el, index) => {
              const itemIndex = mainPics && mainPics.findIndex((item) => item.url === el.url);
              return (
                <div
                  className={styles.picture}
                  key={el.id}
                  onClick={() => onPictureClick(el.url)}
                >
                  <img
                    alt=""
                    className={styles.img}
                    src={el.url}
                  />
                  <span
                    className={styles.checkbox}
                    style={mainPics && itemIndex !== -1 ? {
                      border: 'none',
                      backgroundColor: '#1978FF',
                    } : {}}
                  >
                    <div
                      className={styles.isChecked}
                      style={mainPics && itemIndex !== -1 ? {} : { display: 'none' }}
                    />
                  </span>
                </div>
              );
            })
          ) : null
        }
      </div>
    </div>
  );
});

