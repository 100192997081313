import { message } from 'antd';
import type { EgGridModel } from 'egenie-utils';
import { observable, computed, action } from 'mobx';
import { nanoid } from 'nanoid';

type InitTaobao = (saleProps: [], goodsPropPicList: [], goodsSkuList: []) => void;
type InitTianmao = (saleProps: [], goodsPropPicList: [], goodsSkuList: [], forbidCustomSize: boolean, sizeOptions: []) => void;
type InitDouyin = (saleProps: [], goodsPropPicList: [], goodsSkuList: [], hasTimeVar: number) => void;

type InitDataFunction = InitTaobao | InitTianmao | InitDouyin;

export abstract class SkuBaseStore {
  @observable public tempSaveSkuList = [];// 暂存初始状态下的sku list

  @observable public addSizeDisplay = false;// 是否展示添加尺码

  @observable public addColorDisplay = false; // 是否展示添加颜色

  @observable public goodsSkuList = [];// sku列表

  @observable public sizeList = [];// 尺码列表

  @observable public inputValue = '';// 输入框中的内容

  @observable public colorList = [];// 颜色列表

  @observable public colorPropName = '';// 颜色类名

  @observable public sizePropName = '';// 尺码类名

  @observable public sizeExpand = true; // 是否展开尺码 true为展开

  @observable public colorExpand = true; // 是否展开颜色 true为展开

  public productSaleSpecGridModel: EgGridModel;// 商品规格表

  // 展示更多尺码收起标签
  @computed public get showSizeCollapse() {
    return this.sizeList.length > 10;
  }

  // 展示更多颜色收起标签
  @computed public get showColorCollapse() {
    return this.colorList.length > 10;
  }

  @computed public get first10SizeOption() {
    return this.sizeList.slice(0, 10);
  }

  @computed public get first10ColorOption() {
    return this.colorList.slice(0, 10);
  }

  // 展开/收起尺码选项
  @action public toggleExpandSize = (expandFlag: boolean) => {
    this.sizeExpand = expandFlag;
  };

  // 展开/收起尺码选项
  @action public toggleExpandColor = (expandFlag: boolean) => {
    this.colorExpand = expandFlag;
  };

  // 点击添加颜色
  @action public addColorInput = () => {
    // 判断之前填写的颜色都上传了图片
    const checkedColorList = this.colorList?.filter((item) => item.checked);
    if (checkedColorList?.some((item) => !item.url)) {
      message.warn('请为没有图片的颜色添加图片');
      return;
    }
    this.addColorDisplay = true;
  };

  // 点击颜色值编辑
  @action public editColor = (index, property) => {
    this.colorList[index].editStatus = true;
    this.inputValue = property;
  };
  
  // 点击尺码值编辑
  @action public editSize = (index, property) => {
    this.sizeList[index].editStatus = true;
    this.inputValue = property;
  };

  // 点击添加尺码
  @action public addSizeInput = () => {
    this.addSizeDisplay = true;
  };

  // 输入框中值的变化
  @action public onInputValueChange = (val) => {
    this.inputValue = val;
  };

  // 删除颜色图片
  @action public deleteColorImg = (id: number | string) => {
    const colorIndex = this.colorList.findIndex((item) => item.id === id);
    this.colorList[colorIndex].url = '';
  };

  /**
   * 颜色勾选变化
   * @param index 颜色index
   * @param checked 是否选中
  */
  @action public handleColorCheckChange = (index, checked: boolean) => {
    this.colorList[index].checked = checked;
    this.generateSkuList();
  };
 
  /**
   * 尺码勾选变化
   * @param index 尺码index
   * @param checked 是否选中
  */
  @action public handleSizeCheckChange = (index, checked: boolean) => {
    this.sizeList[index].checked = checked;
    this.generateSkuList();
  };

  /**
   * 关闭所有的编辑框
   * @param editFlag true为关闭尺码 false为关闭颜色
  */
  @action public closeAllEdit = (editFlag?) => {
    editFlag ? this.sizeList.forEach((item) => {
      item.editStatus = false;
    }) : this.colorList.forEach((item) => {
      item.editStatus = false;
    });
  };

  /**
   * 输入颜色
   * @param index 改变颜色的索引 编辑时传
  */
  @action public onColorInput = (index?: number) => {
    if (!this.inputValue) {
      this.addColorDisplay = false;
      this.closeAllEdit();
      return;
    }
  
    if (this.inputValue.length > 30) {
      message.warn('最多只能输入30个字');
      return;
    }
  
    if (this.inputValue.includes(';') || this.inputValue.includes(':')) {
      this.addColorDisplay = false;
      this.closeAllEdit();
      message.warn('颜色中不能出现特殊字符');
      this.inputValue = '';
      return;
    }
      
    const addProperties = this.inputValue.trim();
  
    // 验重
    if (this.colorList?.some((item) => item.name === addProperties)) {
      message.warn('不能输入重复颜色');
      this.addColorDisplay = false;
      this.closeAllEdit();
      this.inputValue = '';
      return;
    }
  
    // 新增颜色
    if (this.addColorDisplay) {
      this.colorList.push({
        name: addProperties,
        url: '',
        checked: true,
      });
  
      this.inputValue = '';
      this.addColorDisplay = false;
  
      // 生成新的sku
      this.generateSkuList();
    } else {
      // 原来的值
      const originValue = this.colorList[index].name;
      this.inputValue = '';
      this.colorList[index].name = addProperties;
      this.colorList[index].editStatus = false;
  
      // 编辑的话手动处理skuList
      this.goodsSkuList.forEach((item) => {
        if (item.goodsSpec.includes(originValue)) {
          item.goodsSpec = item.goodsSpec.replace(originValue, addProperties);
        }
      });
      this.productSaleSpecGridModel.rows = this.mapSpecRows(this.goodsSkuList);
    }
  };

  /**
   * 输入尺码
   * @param index 改变尺码的索引 编辑时传
  */
  @action public onSizeInput = (index?) => {
    if (!this.inputValue) {
      this.addSizeDisplay = false;
      this.closeAllEdit(true);
      return;
    }

    if (this.inputValue.length > 30) {
      message.warn('最多只能输入30个字');
      return;
    }

    const addSize = this.inputValue.trim();

    if (this.inputValue.includes(':')) {
      this.addSizeDisplay = false;
      this.closeAllEdit(true);
      message.warn('尺码中不能出现特殊字符');
      return;
    }

    // 验重
    if (this.sizeList?.some((item) => item.name === addSize)) {
      message.warn('不能输入重复尺码');
      this.addSizeDisplay = false;
      this.closeAllEdit(true);
      this.inputValue = '';
      return;
    }

    // 新增
    if (this.addSizeDisplay) {
      this.sizeList.push({
        key: nanoid(5),
        name: addSize,
        checked: true,
      });
      this.inputValue = '';
      this.addSizeDisplay = false;
      this.generateSkuList();
    } else {
      // 编辑
      const originValue = this.sizeList[index].name;
      const newSize = this.inputValue;
      
      this.sizeList[index].name = newSize;

      // 编辑的话手动处理skuList
      this.goodsSkuList.forEach((item) => {
        if (item.goodsSpec.includes(originValue)) {
          item.goodsSpec = item.goodsSpec.replace(originValue, newSize);
        }
      });
      this.productSaleSpecGridModel.rows = this.mapSpecRows(this.goodsSkuList);
      this.inputValue = '';
      this.sizeList[index].editStatus = false;
    }
  };

  // 映射表格的行(交由各个平台自己实现)
  public abstract mapSpecRows: (arr: Array<Record<string, any>>) => Array<Record<string, any>>;

  // 生成sku表(交由各个平台自己实现)
  public abstract generateSkuList: () => void;

  // 校验sku表(交由各个平台自己实现)
  public abstract saveValidate: () => boolean;

  // 添加利润(交由各个平台自己实现)
  public abstract handleSalePriceAddProfit: (profit: number, multiAdd?: number) => void;

  // 初始化数据(交由各个平台自己实现)
  public abstract initData: InitDataFunction;
}
