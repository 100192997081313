export const api = {
  picManage: {
    getFolderContent: '/api/goodsPic/rest/picManage/getPicFolderContent', // 获取图片目录
    queryFilesPage: '/api/goodsPic/rest/picManage/queryFilesPage', // 图片管家分页数据
    createFolder: '/api/goodsPic/rest/picManage/createFolder', // 创建图片文件夹
    upload: '/api/goodsPic/rest/picManage/uploadPicture', // 上传图片
  },
  videoManage: {
    getFolderContent: '/api/goodsPic/rest/videoManage/getVideoFolderContent', // 获取视频目录
    queryFilesPage: '/api/goodsPic/rest/videoManage/queryFilesPage', // 获取视频分页数据
    createFolder: '/api/goodsPic/rest/videoManage/createFolder', // 创建视频文件夹
    upload: '/api/goodsPic/rest/videoManage/uploadVideo', // 上传视频
  },
};
