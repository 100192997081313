import { PlusOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import React from 'react';
import deleteIcon from '../../../assets/images/deleteIcon.png';
import styles from './index.less';

interface IUploadTaobao{
  type: string;
  selectVideo: (type, key) => void;
  deleteVideo: (type, key) => void;
  fileList: IEachVideo[];
}

interface IEachVideo{
  id: string;
  url: string;
}

@observer
class SingleUploadVideo extends React.Component<IUploadTaobao> {
  render(): JSX.Element {
    const { selectVideo, fileList, deleteVideo, type } = this.props;
    return (
      <div>
        {
          fileList && fileList.length > 0 && fileList[0].url ? (
            <div className={styles.imgWrap}>
              <div className={styles.imgCom}>
                <video
                  controls
                  preload="meta"
                  src={fileList[0].url}
                />
              </div>
              <img
                className={styles.delete}
                onClick={() => {
                  deleteVideo(type, fileList[0]?.id);
                }}
                src={deleteIcon}
              />
            </div>
          ) : (
            <div
              className={styles.singleUploadImgWrap}
              onClick={() => {
                selectVideo(type, fileList[0]?.id);
              }}
            >
              <div className={styles.plusIcon}>
                <PlusOutlined style={{ fontSize: 20 }}/>
              </div>
              <div>
                上传视频
              </div>
            </div>
          )
        }
      </div>
    );
  }
}

export { SingleUploadVideo };

