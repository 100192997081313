import type { FormInstance } from 'antd';
import { observable, action } from 'mobx';
import React from 'react';
import { timeSkuNoDict } from '../saleInfo/sizeColorStore';

class Store {
  constructor(options) {
    this.parent = options;
  }

  @observable public parent;

  @observable public deliveryModesList = [];// 支持的发货模式

  @observable public deliveryMode = 'normalRule';// 发货模式

  @observable public presellConfigLevel = 0;// 2:现货+预售 3.新预售

  @observable public presellType = 0;

  @observable public listingTimeMode = 0;

  @observable public reduceType = 1;

  @observable public presellDelay = 2;

  @observable public deliveryDelayDay = 1;

  @observable public presellEndTime = null;

  @observable public warrantyService = [
    '1',
    '2',
    '3',
  ];

  @observable public freightTemplateList = [];

  @observable public freightId = '';

  @observable public afterSaleOptions = [];// 售后服务选项

  @observable public afterSaleEnabled = false;

  @observable public deliveryInfoFormRef = React.createRef<FormInstance>();

  @observable public linePriceFormRef = React.createRef<FormInstance>();

  @observable public serviceInfoFormRef = React.createRef<FormInstance>();

  // 发货模式改变
  @action public changeDeliveryType = (e) => {
    this.deliveryMode = e.target.value;
    let hasTimeVar = 0;

    if (this.deliveryMode === 'timeSkuPresellWithNormalRule') {
      hasTimeVar = 2;
      this.parent.sizeColorStore.timeSelectList = ['48小时内发货'];
      this.parent.sizeColorStore.hasTimeVar = hasTimeVar;
      this.parent.sizeColorStore.mergeTimeSkuList();

      // 重新生成sku
      this.parent.sizeColorStore.goodsSkuList.forEach((eachSku) => {
        this.parent.sizeColorStore.timeSelectList.forEach((eachTimeVar) => {
          eachSku.goodsSpec = `${eachSku.goodsSpec};发货时效:${eachTimeVar}`;
          eachSku.goodsSkuNo = `${eachSku.goodsSkuNo}-${timeSkuNoDict[eachTimeVar]}`;
        });
      });
      this.parent.sizeColorStore.productSaleSpecGridModel.rows = this.parent.sizeColorStore.mapSpecRows(this.parent.sizeColorStore.goodsSkuList);
      return;
    }

    if (this.deliveryMode === 'timeSkuPurePresellRule') {
      hasTimeVar = 3;
    }

    this.parent.sizeColorStore.hasTimeVar = hasTimeVar;
    this.parent.sizeColorStore.timeSelectList = [];
    this.parent.sizeColorStore.mergeTimeSkuList();
  };
}

export default Store;
