import { Steps } from 'antd';
import React from 'react';
import styles from './index.less';

const STEPS = [
  {
    key: '1',
    title: '选择类目',
  },
  {
    key: '2',
    title: '完善商品信息',
  },
];

const { Step } = Steps;

export const StepsComponent = (props: { currentStep: number; }) => {
  const { currentStep } = props;
  return (
    <div
      className={styles.stepWrap}
      id="stepHeader"
    >
      <Steps
        current={currentStep}
      >
        {
          STEPS.map((item) => (
            <Step
              key={item.key}
              title={item.title}
            />
          ))
        }
      </Steps>
    </div>
  );
};

