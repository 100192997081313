import type { FormInstance } from 'antd';
import { action, observable } from 'mobx';
import React from 'react';
import { getStr } from '../../../../../utils';
import { charaRegGlobal } from '../../../../shelvesBase';
import type { IObj } from '../../../interface';
import { MaterialStore } from '../../../../../pages/shelvesNew/components';

export default class Store {
  constructor(options) {
    this.parent = options;
  }

  @observable public parent;

  @observable public initialValues = {
    goodsName: '',
    recommendRemark: '',
    payType: 1,
  };

  @observable public goodsProps = [];

  @observable public classificationList: Array<{ label: string; value: string | number; }>;

  @observable public baseinfoFormRef = React.createRef<FormInstance>();

  @observable public sizeTemplateOptions = [];

  public materialStore = new MaterialStore(this);

  @observable public hasMaterialProps = false;

  @action public changeInput = (e, type, maxLength) => {
    let valueTmp = e.target.value;
    if (this.calcStrLen(valueTmp) > maxLength) {
      valueTmp = getStr(valueTmp, maxLength);
    }
    const { setFieldsValue } = this.baseinfoFormRef.current;
    setFieldsValue({ [type]: valueTmp });
    Object.assign(this.initialValues, { [type]: valueTmp });
  };

  @action public blurInput = (e, info) => {
    const { name } = info;
    if (name === '货号') {
      this.parent.sellNo = e.target.value;
      this.parent.salePropsStore.getSkuInfo();
    }
  };

  @action public calcStrLen = (str: string): number => {
    const strTemp = str.replace(charaRegGlobal, 'rr');
    return strTemp.length;
  };

  @action public getBaseInfo = () => {
    const { getFieldsValue } = this.baseinfoFormRef.current;
    const values = getFieldsValue();
    const { goodsName, recommendRemark, payType, sizeInfoTemplateId } = values;
    const goodsProps = [];
    this.goodsProps.forEach((item) => {
      let objTmp: IObj = {};
      const { id, name, type } = item;
      objTmp.id = id;
      objTmp.name = name;
      if (type !== 'INPUT') {
        objTmp.value = typeof values[id] === 'object' ? (values[id] && values[id].join(',')) : values[id];
      } else {
        objTmp.valueName = values[id];
      }
      goodsProps.push(objTmp);
      objTmp = {};
    });
    return {
      goodsName,
      recommendRemark,
      payType,
      goodsProps,
      sizeInfoTemplateId,
    };
  };

  @action public writeBackBaseinfo = (goodsProps, goodsName, recommendRemark, payType, sizeInfoTemplateId) => {
    const obj = {};
    goodsProps && goodsProps.map((item) => {
      const { id, type, value, valueName } = item;
      const multiValue = type === 'MULTICHECK' ? (typeof value === 'string' ? value.split(',') : value) : value;
      Object.assign(obj, { [id]: valueName || (multiValue || undefined) });
    });
    Object.assign(obj, {
      goodsName,
      recommendRemark,
      payType,
      sizeInfoTemplateId,
    });
    const { setFieldsValue } = this.baseinfoFormRef.current;
    setFieldsValue(obj);
    Object.assign(this.initialValues, obj);
  };
}
