import { Button, Modal } from 'antd';
import React from 'react';
import failIcon from '../../../assets/images/failIcon.png';
import submitInProgressIcon from '../../../assets/images/submitInProgress.png';
import successIcon from '../../../assets/images/succesIcon.png';
import styles from './index.less';

const platformDict = {
  '-100': {
    name: '天猫',
    url: 'https://detail.tmall.com/item.htm?id=',
  },
  '1': {
    name: '淘宝',
    url: 'https://item.taobao.com/item.html?ft=t&id=',
  },
  '21': {
    name: '抖音',
    url: 'https://haohuo.jinritemai.com/views/product/detail?id=',
  },
  '17': {
    name: '拼多多',
    url: 'https://mms.pinduoduo.com/goods/goods_detail?goods_id=',
  },
  '22': {
    name: '快手',
    url: 'https://app.kwaixiaodian.com/merchant/shop/detail?id=',
  },
  '32': {
    name: '抖音供应商版',
    url: null,
  },
  '31': {
    name: '视频号',
    url: null,
  },
};

interface PublishResultProps {
  submitResult: string;
  visibleSubmitResult: boolean;
  closeModal: () => void;// 关闭整个页签
  closeResultModal: () => void;// 关闭结果弹窗
  shopName: string;
  platformId: string | number;
  listingTimeMode: string | number;
  goodsId: string | number;
  errorMessage?: string;
}

export const PublishResult: React.FC<PublishResultProps> = (props) => {
  const {
    submitResult,
    visibleSubmitResult,
    closeModal,
    closeResultModal,
    errorMessage,
    listingTimeMode,
    shopName,
    platformId,
    goodsId,
  } = props;
  const platformName = platformDict[platformId].name;
  const tip = submitResult === 'Successful' ? '提交成功' : submitResult === 'Failed' ? '提交失败' : '提交中';
  const url = submitResult === 'Successful' ? successIcon : submitResult === 'Failed' ? failIcon : submitInProgressIcon;
  const successMsg = listingTimeMode == 0 ? `商品已提交至${shopName}（${platformName}），请稍后确认是否上架成功。若上架失败，可能是${platformName}审核未通过，请前往${platformName}查看原因` : `商品已放入${shopName}（${platformName}）草稿箱`;

  return (
    <Modal
      centered
      className={styles.publishModalWrapper}
      closable={false}
      destroyOnClose
      footer={null}
      maskClosable={false}
      open={visibleSubmitResult}
      title=""
      width={400}
    >
      <div className={styles.saveResultWrap}>
        <img
          alt="info"
          src={url}
          style={{
            width: 58,
            height: 58,
          }}
        />
        <div className={styles.tip}>
          {tip}
        </div>
        <div className={styles.info}>
          <span>
            {
              submitResult === 'Failed' ? <ErrorMessage text={errorMessage}/> : submitResult === 'Successful' ? successMsg : (
                <span>
                  上架需要大约
                  <span style={{ color: '#1978ff' }}>
                    {platformId == 17 ? 3 : 1}
                    分钟
                  </span>
                  ，可稍后返回或直接前往我的款式查看结果
                </span>
              )
            }
          </span>
        </div>
        <div>
          {
            submitResult !== 'Failed' && (
              <Button
                className={styles.customButton}
                onClick={closeModal}
              >
                关闭
              </Button>
            )
          }
          {
            submitResult === 'inProgress' && (
              <Button
                className={styles.customButton}
                onClick={() => {
                  window.top.egenie.openTabId(256);
                }}
                type="primary"
              >
                我的款式
              </Button>
            )
          }
          {
            submitResult === 'Successful' && platformDict[platformId].url && (
              <Button
                className={styles.customButton}
                onClick={() => {
                  window.open(platformDict[platformId].url + goodsId);
                }}
                style={{ marginLeft: 0 }}
              >
                前往查看
              </Button>
            )
          }
          {
            submitResult !== 'Failed' && (
              <Button
                onClick={() => {
                  window.open('/egenie-ts-vogue/homePage/index');
                }}
                type="primary"
              >
                继续选款
              </Button>
            )
          }
          {
            submitResult === 'Failed' && (
              <Button
                className={styles.customButton}
                onClick={closeResultModal}
              >
                我知道了
              </Button>
            )
          }
        </div>
      </div>
    </Modal>
  );
};

const ErrorMessage = (props: { text: string; }) => {
  const createMarkUp = { __html: props.text };
  return (
    <span dangerouslySetInnerHTML={createMarkUp}/>
  );
};
