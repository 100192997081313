import { Affix, Button, Row, Col } from 'antd';
import React from 'react';
import styles from './index.less';

interface FootPartProps{
  save: () => void;
  submitInfo: () => void;
  footerStyle?: React.CSSProperties;
}

/**
   * 上架第二步底部按钮组件
   * @param save 保存
   * @param submitInfo 上架
   * @param footerStyle 底部样式
  */
export const FootPart: React.FC<FootPartProps> = (props) => {
  const { save, submitInfo, footerStyle } = props;
  return (
    <Affix offsetBottom={0}>
      <div
        className={styles.footerWrap}
        style={footerStyle}
      >
        <Row className={styles.w100}>
          <Col span={3}/>
          <Col span={21}>
            <div className={styles.titleWrap}>
              {
                props.children
              }
              <Button
                onClick={submitInfo}
                type="primary"
              >
                一键铺货
              </Button>
              <Button
                className={styles.saveButton}
                onClick={save}
              >
                保存
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </Affix>
  );
};

