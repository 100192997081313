import React from 'react';
import styles from './index.less';

interface ITitle{
  text: string;
  id: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
}

// 标题
export const ContentWrap = (props: ITitle) => {
  const { text, id } = props;
  return (
    <div
      className={styles.eachContentWrapper}
      style={props.style}
    >
      <div
        className={styles.headTitleWrap}
        id={id}
      >
        <div className={styles.divider}/>
        <span className={styles.headTitle}>
          {text}
        </span>
      </div>
      {props.children}
    </div>
  );
};
