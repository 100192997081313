import { message } from 'antd';
import { action, observable } from 'mobx';
import { nanoid } from 'nanoid';
import { UploadStore } from '../../../../../utils/upLoad/uploadStore';
import { arrayMove } from 'react-sortable-hoc';

interface IVideo{
  url: string;
  id: string;
}

export default class Store {
  constructor(options) {
    this.parent = options;
  }

  @observable public imgLength = 50;

  @observable public parent;

  @observable public mainPicTip = '建议上传图片比例1:1，至少600*600，大小5M以内，至少上传3张主图';

  @observable public type = '';

  @observable public code = '';

  @observable public qualityReportList = [];// 质检报告图片

  @observable public videoFileList: IVideo[] = [];

  @action public onSortEnd = ({ oldIndex, newIndex }): void => {
    if (oldIndex !== newIndex) {
      this.qualityReportList = arrayMove(this.qualityReportList, oldIndex, newIndex);
    }
  };

  @action public callBack = async(params) => {
    const { mainPics } = this.parent.mainImgsStore;
    const { imgs } = this.parent.productInfoStore;
    const { colorList } = this.parent.sizeColorStore;
    const nowImgLength = mainPics.length + imgs.length + colorList.length;
    const canPush = this.imgLength - nowImgLength;
    if (nowImgLength >= this.imgLength) {
      message.warn('上传图片数量超过限制');
      return;
    }
    if (this.type === 'color') {
      const colorIndex = this.parent.sizeColorStore.colorList.findIndex((item) => item.name === this.code);
      this.parent.sizeColorStore.colorList[colorIndex].url = params[0].url;
    } else if (this.type === 'mainPic') {
      const uploadImgs = this.handleImgs(params).slice(0, canPush);

      // 处理主图尺寸
      for (let i = 0; i < uploadImgs.length; i++) {
        uploadImgs[i].url = await this.parent.dealMainImg(uploadImgs[i].url);
      }
      this.parent.mainImgsStore.mainPics = [
        ...mainPics,
        ...uploadImgs,
      ];
    } else if (this.type === 'productInfoImg') {
      const uploadImgs = this.handleImgs(params);
      this.parent.productInfoStore.imgs = [
        ...imgs,
        ...(uploadImgs.slice(0, canPush)),
      ];
    } else if (this.type === 'videoManage') {
      const { id, url } = params[0];
      this.videoFileList = [
        {
          id,
          url,
        },
      ];
    } else if (this.type === 'qualityReport') {
      if (this.qualityReportList.length + params.length >= 20) {
        message.warn('最多只能上传20张');
        return;
      }
      this.qualityReportList = this.qualityReportList.concat(params.map((item) => ({
        id: item.id,
        url: item.url,
      })));
    }
    this.type = '';
  };

  @action public handleImgs = (imgs) => {
    return imgs.map((item) => {
      item.imgId = nanoid();
      return item;
    });
  };

  @observable public uploadStoreImg = new UploadStore({
    type: 'picManage', // picManage 图片 videoManage 视频
    multiple: false, // 是否允许多选文件
    limitNumber: 1, // 限制只能上传一个文件或者说只能选择一个文件
    callBack: this.callBack,
  });

  @action public selectImg = (type, code?): void => {
    const { mainPics } = this.parent.mainImgsStore;
    const { imgs } = this.parent.productInfoStore;
    const { colorList } = this.parent.sizeColorStore;
    if (mainPics.length + imgs.length + colorList.length >= this.imgLength) {
      message.error('主图、详情图和颜色图总共不超过50张');
      return;
    }
    this.type = type;
    this.code = code;
    let imgSize;
    let videoSize;
    if (type === 'mainPic') {
      imgSize = {
        ratio: 1,
        minWidth: 600,
      };
    }

    if (type === 'color') {
      imgSize = { ratio: 1 };
    }

    if (type === 'videoManage') {
      videoSize = {
        ratio: [1],
        minWidth: 100,
        duration: 60,
      };
    }
    this.uploadStoreImg = new UploadStore({
      type: this.type !== 'videoManage' ? 'picManage' : 'videoManage', // picManage 图片 videoManage 视频
      // activeTab: 'manage',
      multiple: Boolean(type === 'mainPic') || Boolean(type === 'productInfoImg'), // 是否允许多选文件
      limitNumber: (type === 'color' || type === 'videoManage') ? 1 : 9999, // 限制只能上传一个文件或者说只能选择一个文件
      callBack: this.callBack,
      imgSize,
      videoSize,
    });
    this.uploadStoreImg.onOpen();
  };

  // 删除颜色图片
  @action public deleteImg = (type, key) => {
    // 目前只有抖音这一种情况
    if (type === 'color') {
      // 颜色
      const deleteIndex = this.parent.sizeColorStore.colorList.findIndex((item) => item.name === key);
      this.parent.sizeColorStore.colorList[deleteIndex].url = '';
    }

    if (type === 'videoManage') {
      this.videoFileList = [];
    }

    if (type === 'qualityReport') {
      this.qualityReportList = this.qualityReportList.filter((item) => item.id !== key);
    }
  };
}
