import type { BaseData } from 'egenie-common';
import { request } from 'egenie-utils';
import type { ICategoryNode } from './interface';

/**
   * 获取类目的请求
   * @params url 请求url
   * @params method 请求方法
   * @params shopId 店铺id
   * @params parentCid 父类目id
  */
export const categoryRequest = (url: string, method, shopId, parentCid?) => {
  const requestObj = {
    url,
    method,
  };

  // 兼容天猫和淘宝(二三级请求)
  if (method === 'GET') {
    // 二级传categoryType 和 parentCategoryId
    Object.assign(requestObj, {
      params: {
        categoryType: 25,
        parentCategoryId: parentCid,
      },
    });
  } else {
    Object.assign(requestObj, {
      data: {
        shopId,
        parentCid: parentCid || 0,
      },
    });
  }

  return request<BaseData<ICategoryNode[]>>(requestObj);
};

export const URL_DICT = {
  '-100': '/api/pos/goods/rest/gim/category/findOneLevel',
  '1': '/api/pos/goods/rest/gim/category/findOneLevel',
  '21': '/api/gms/goods/platform/douyin/queryItemCats',
  '17': '/api/gms/goods/platform/pdd/queryItemCats',
  '22': '/api/gms/goods/platform/ks/queryItemCats',
  '32': '/api/gms/goods/platform/dySupplyChain/queryItemCats',
  '31': '/api/gms/goods/platform/weixin/queryItemCats',
};

export const URL_MATCH_DICT = {
  '21': '/api/gms/goods/platform/douyin/matchItemCats',
  '17': '/api/gms/goods/platform/pdd/matchItemCats',
  '1': '/api/gms/goods/platform/matchItemCats',
  '-100': '/api/gms/goods/platform/matchItemCats',
  '22': '/api/gms/goods/platform/ks/matchItemCats',
  '31': '/api/gms/goods/platform/weixin/matchItemCats',
  '32': '/api/gms/goods/platform/dySupplyChain/matchItemCats',
};

// 批量铺货匹配平台类目URL
export const URL_MATCH_DICT_BATCH = { '17': '/api/gms/goods/platform/pdd/batchStyle/matchItemCats' };
