import { message } from 'antd';
import { EgGridModel } from 'egenie-utils';
import _ from 'lodash';
import { observable, action, autorun } from 'mobx';
import { nanoid } from 'nanoid';
import { scrollToAnchor } from '../../../../../utils';
import { SkuBaseStore } from '../../../../shelvesBase';
import { saleSpecColumns } from './columns';

export const timeSkuNoDict = {
  '48小时内发货': 2,
  '5天内发货': 5,
  '15天内发货': 15,
  '45天内发货': 45,
};

export class SizeColorStore extends SkuBaseStore {
  constructor(parent) {
    super();
    this.parent = parent;
    autorun(() => {
      this.productSaleSpecGridModel.columns[3].ejlHidden = !this.hasTimeVar;
    });
  }

  @observable public parent;

  @observable public hasTimeVar = 0;// sku是否加入时效 2为现货+预售 3为新预售

  @observable public timeSkuSpecDetialOptions = [];// 现货+预售

  @observable public timeSkuPurePresellDetailOptions = [];// 新预售时效规格

  @observable public timeSelectList = [];// 选中的时效列表

  @observable public allPrice: number;// 批量价格

  @observable public allNum: number;// 批量数量

  @observable public goodsSkuList = [];// sku列表

  // 商品规格表
  @observable public productSaleSpecGridModel = new EgGridModel({
    columns: saleSpecColumns(this),
    rows: [],
    showPagination: false,
    showSelectedTotal: false,
    showCheckBox: false,
    showRefresh: false,
    showPager: false,
    primaryKeyField: 'goodsSpec',
  });

  // 映射sku列表为商品规格表格的行
  @action public mapSpecRows = (arr) => {
    return arr.map((item) => {
      const { goodsSkuId, goodsSpec, goodsSkuNo, salePrice, stockNum, costPrice } = item;
      const goodsSpecTemp = goodsSpec.split(';');

      const colorSizeTime = {
        color: goodsSpecTemp[0].split(':')[1],
        size: goodsSpecTemp[1].split(':')[1],
        timeVar: goodsSpecTemp[2]?.split(':')[1], // 时效规格
      };

      return {
        goodsSpec,
        goodsSkuId,
        goodsSkuNo,
        costPrice,
        salePrice,
        stockNum,
        ...colorSizeTime,
      };
    });
  };

  // 选择时效
  @action public selectTime = (event, item: string) => {
    const checked = event.target.checked;
    checked ? this.timeSelectList.push(item) : this.timeSelectList = this.timeSelectList.filter((eachTime) => eachTime !== item);
    this.generateSkuList();
  };

  // 生成skulist
  @action public generateSkuList = () => {
    const checkedColorList = this.colorList.filter((item) => item.checked);
    const checkedSizeList = this.sizeList.filter((item) => item.checked);

    // 二重循环生成sku list
    const newList = [];
    checkedColorList.forEach((colorItem) => {
      checkedSizeList.forEach((sizeItem) => {
        const colorName = colorItem.name;
        const { name } = sizeItem;

        // 循环发货时效
        if (!this.timeSelectList.length) {
          const goodsSpec = `${this.colorPropName}:${colorName};${this.sizePropName}:${name}`;// spec
          const eachSku = {
            goodsSpec,
            goodsSkuNo: '',
            stockNum: null,
            salePrice: null,
          };
          newList.push(eachSku);
        } else {
          this.timeSelectList.forEach((timeItem) => {
            const timeSku = timeSkuNoDict[timeItem];
            const goodsSpec = `${this.colorPropName}:${colorName};${this.sizePropName}:${name};发货时效:${timeItem}`;
            const eachSku = {
              goodsSpec,
              goodsSkuNo: '',
              stockNum: null,
              salePrice: null,
            };
            newList.push(eachSku);
          });
        }
      });
    });

    // skulist长度没有变化(发货时效变化的时候才能触发)
    if (this.goodsSkuList.length === newList.length) {
      this.goodsSkuList.forEach((eachSku) => {
        // 取消勾选发货时效
        if (this.timeSelectList.length === 0) {
          eachSku.goodsSpec = eachSku.goodsSpec.slice(0, eachSku.goodsSpec.lastIndexOf(';'));
        } else {
          // 勾选发货时效
          this.timeSelectList.forEach((eachTimeVar) => {
            eachSku.goodsSpec = `${eachSku.goodsSpec};发货时效:${eachTimeVar}`;
          });
        }
      });

      this.productSaleSpecGridModel.rows = this.mapSpecRows(this.goodsSkuList);
      return;
    }
  
    let diffList;

    // 判断减少还是增加sku
    if (this.goodsSkuList.length > newList.length) {
      if (!newList.length) {
        diffList = this.goodsSkuList;
      } else {
        diffList = _.differenceBy(this.goodsSkuList, newList, 'goodsSpec');
      }

      // 减少sku的话如果减少的sku中含有skuId是初使状态下就有的sku 要保存起来 防止又重新勾选
      const saveList = diffList.filter((item) => item.goodsSkuId);
      this.tempSaveSkuList.push(...saveList);

      this.goodsSkuList = _.intersectionBy(this.goodsSkuList, newList, 'goodsSpec');
    } else {
      if (!this.goodsSkuList.length) {
        diffList = newList;
      } else {
        diffList = _.differenceBy(newList, this.goodsSkuList, 'goodsSpec');
      }

      // 新增sku
      // 检查新增sku是否是初始状态下就有的
      const initList = _.intersectionBy(this.tempSaveSkuList, diffList, 'goodsSpec');

      this.goodsSkuList.push(...initList);
      const restList = _.differenceBy(diffList, initList, 'goodsSpec');

      this.goodsSkuList.push(...restList);
    }

    this.productSaleSpecGridModel.columns = saleSpecColumns(this);

    this.productSaleSpecGridModel.rows = this.mapSpecRows(this.goodsSkuList);
  };

  // /售价数量修改
  // 修改单个sku售价
  @action public handleSingleSalePriceChange = (spec, price) => {
    const index = this.goodsSkuList.findIndex((item) => item.goodsSpec === spec);
    this.goodsSkuList[index].salePrice = price;
    this.productSaleSpecGridModel.rows = this.mapSpecRows(this.goodsSkuList);
  };

  // 修改所有sku售价(Change)
  @action public handleAllSalePriceChange = (price) => {
    this.allPrice = price;
  };

  // 修改sku Blur
  @action public handleSkuChange = (spec, val) => {
    const index = this.goodsSkuList.findIndex((item) => item.goodsSpec === spec);
    this.goodsSkuList[index].goodsSkuNo = val;
    this.productSaleSpecGridModel.rows = this.mapSpecRows(this.goodsSkuList);
  };

  // blur
  @action public handleAllSalePriceBlur = () => {
    if (!this.allPrice) {
      return;
    }
    this.goodsSkuList.forEach((item) => {
      item.salePrice = this.allPrice;
    });
    this.productSaleSpecGridModel.columns = saleSpecColumns(this);
    this.productSaleSpecGridModel.rows = this.mapSpecRows(this.goodsSkuList);
    this.allPrice = null;
  };

  // 添加利润
  @action public handleSalePriceAddProfit = (profit: number) => {
    this.goodsSkuList.forEach((item) => {
      if (item.costPrice) {
        item.salePrice = item.costPrice + profit;
      } else {
        item.salePrice = null;
      }
    });
    this.productSaleSpecGridModel.columns = saleSpecColumns(this);
    this.productSaleSpecGridModel.rows = this.mapSpecRows(this.goodsSkuList);
    scrollToAnchor('skuTable');
  };

  @action public handleAllNumChange = (num) => {
    this.allNum = num;
  };

  @action public handleAllNumBlur = () => {
    if (!this.allNum) {
      return;
    }
    this.goodsSkuList.forEach((item) => {
      item.stockNum = this.allNum;
    });
    this.productSaleSpecGridModel.columns = saleSpecColumns(this);
    this.productSaleSpecGridModel.rows = this.mapSpecRows(this.goodsSkuList);
    this.allNum = null;
  };

  // 修改单个sku数量
  @action public handleSingleNumChange = (spec, num) => {
    const index = this.goodsSkuList.findIndex((item) => item.goodsSpec === spec);
    this.goodsSkuList[index].stockNum = num;
    this.productSaleSpecGridModel.rows = this.mapSpecRows(this.goodsSkuList);
  };

  // 初始化颜色数量
  @action public initData = (saleProps: any[], goodsPropPicList: any[], goodsSkuList: any[], hasTimeVar: number) => {
    this.hasTimeVar = hasTimeVar;

    // 尺码列表
    const sizeObj = saleProps.find((item) => item.propsName.includes('尺码') || item.propsName.includes('尺寸') || item.propsName.includes('身高'));
    this.sizeList = sizeObj?.values.map((item) => ({
      key: nanoid(5),
      name: item.name,
      checked: true,
      editStatus: false,
    }));

    this.sizePropName = sizeObj?.propsName;

    // 颜色列表
    const colorObj = saleProps.find((item) => item.propsName.includes('颜色'));
    this.colorPropName = colorObj?.propsName;
    this.colorList = colorObj?.values.map((item) => {
      const picItem = goodsPropPicList.find((eachPic) => eachPic.properties === `${this.colorPropName}:${item.name}`);
      return {
        key: nanoid(5),
        name: item.name,
        checked: true,
        editStatus: false,
        url: picItem?.url || '',
      };
    });

    // 时效列表
    const timeObj = saleProps.find((item) => item.propsName === '发货时效');
    this.timeSelectList = timeObj?.values.map((item) => (item.name)) || [];

    // 处理sku列表
    // 如果skuList不为数组，直接置空
    if (!(Array.isArray(goodsSkuList))) {
      this.productSaleSpecGridModel.rows = [];
    }

    // 加入时效规格后不能用goodsSpec当作主key
    this.goodsSkuList = goodsSkuList;

    // 给库存价格预售时间初始值
    this.goodsSkuList.forEach((item) => {
      item.salePrice = null;
      item.stockNum = item.stockNum || 100;
    });

    // 防止初始状态下修改价格和数量报错
    this.productSaleSpecGridModel.columns = saleSpecColumns(this);
        
    // 填入表格行
    this.productSaleSpecGridModel.rows = this.mapSpecRows(this.goodsSkuList);
  };

  // 保存提交校验
  @action public saveValidate = () => {
    // 校验颜色图片
    const checkedColorList = this.colorList.filter((item) => item.checked);
    if (checkedColorList.some((item) => !item.url)) {
      message.warn('请为选中颜色上传图片');
      this.toggleExpandColor(true);
      return false;
    }

    // 保存的话只校验销售信息
    if (!this.goodsSkuList.length) {
      message.warn('请填写商品规格');
      return false;
    }

    // 校验商品规格信息
    if (this.goodsSkuList.some((item) => {
      return !item.salePrice || !item.stockNum || (this.hasTimeVar > 0 && !item.goodsSpec.includes('发货时效'));
    })) {
      message.warn('请完善商品规格信息');
      return false;
    }

    // 现货+预售发货模式 除了48小时 还必须选一个其他的
    if (this.hasTimeVar === 2) {
      if (this.timeSelectList.length < 2) {
        message.warn('请检查发货时效');
        return false;
      }
    }

    return true;
  };

  // 合并时效列
  @action public mergeTimeSkuList = () => {
    this.goodsSkuList.forEach((eachSku) => {
      if (eachSku.goodsSpec.includes('发货时效')) {
        eachSku.goodsSpec = eachSku.goodsSpec.slice(0, eachSku.goodsSpec.lastIndexOf(';'));
        eachSku.goodsSkuNo = eachSku.goodsSkuNo.slice(0, eachSku.goodsSkuNo.lastIndexOf('-'));
      }
    });
    this.goodsSkuList = _.uniqBy(this.goodsSkuList, 'goodsSpec');
    this.productSaleSpecGridModel.rows = this.mapSpecRows(this.goodsSkuList);
  };
}
