import { Button, InputNumber, Form, Input, Radio, Checkbox, Row, Col, Select, Space, Affix, DatePicker } from 'antd';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useEffect } from 'react';
import { formLayout } from '../../../../shelvesBase';
import { rulesValueDict } from './constant';
import styles from './index.less';

const { Item } = Form;

const limitNum = (value: string): number => {
  const reg = /^[+]{0,1}(\d+)$/;
  if (reg.test(value) || value === '0') {
    return Number(value);
  } else {
    return 2;
  }
};

export const DeliveryInfoForm = observer((props) => {
  const { deliveryInfoFormRef, deliveryModesList, changeDeliveryType, deliveryMode, presellType, presellDelay, reduceType, deliveryDelayDay, presellEndTime } = props.store;
  return (
    <Form
      ref={deliveryInfoFormRef}
      {...formLayout}
      initialValues={{
        presellType,
        presellDelay,
        deliveryMode,
        reduceType,
        deliveryDelayDay,
        presellEndTime,
      }}
    >
      <Item
        label="发货模式"
        name="deliveryMode"
        rules={[{ required: true }]}
      >
        <Radio.Group onChange={changeDeliveryType}>
          <Space>
            {
              deliveryModesList?.length > 0 && deliveryModesList.map((item) => {
                return (
                  <Radio
                    key={item}
                    value={item}
                  >
                    {rulesValueDict[item].name}
                  </Radio>
                );
              })
            }
          </Space>
        </Radio.Group>
      </Item>
      {deliveryMode === 'normalRule' && (<NormalRuleDisplay/>)}
      {deliveryMode === 'productPresellRule' && (<ProductPresellRuleDisplay/>)}
      <Item
        label="订单库存计数"
        name="reduceType"
        rules={[{ required: true }]}
      >
        <Radio.Group>
          <Space>
            <Radio
              value={1}
            >
              下单减库存
            </Radio>
            <Radio
              value={2}
            >
              付款减库存
            </Radio>
          </Space>
        </Radio.Group>
      </Item>
    </Form>
  );
});

// 现货发货展示内容
const NormalRuleDisplay = () => {
  return (
    <Item
      label="现货发货时间"
      name="deliveryDelayDay"
      rules={[{ required: true }]}
    >
      <Radio.Group>
        <Space>
          <Radio
            value={9999}
          >
            当日发
          </Radio>
          <Radio
            value={1}
          >
            次日发
          </Radio>
          <Radio
            value={2}
          >
            48小时
          </Radio>
        </Space>
      </Radio.Group>
    </Item>
  );
};

// 全款预售展示内容
const ProductPresellRuleDisplay = () => {
  return (
    <>
      <Item
        label="预售结束时间"
        name="presellEndTime"
        rules={
          [
            {
              required: true,
              message: '请选择预售结束时间',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                const now = moment(Date.now()).format('YYYY-MM-DD HH:mm:ss');
                const endTime = moment(value).format('YYYY-MM-DD HH:mm:ss');
                const jiange = moment(endTime).diff(moment(now), 'seconds');
                if (!value) {
                  return Promise.resolve();
                }
                if (jiange > 30 * 24 * 3600 || jiange < 3600) {
                  return Promise.reject('预售结束时间不可以早于当前时间后1小时并且不超过30天');
                }
                return Promise.resolve();
              },
            }),
          ]
        }
      >
        <DatePicker
          format="YYYY-MM-DD HH:mm:ss"

          // disabledDate={disabledDate}
          showNow={false}
          showTime
        />
      </Item>
      <Item
        label="预售发货时间"
        required
      >
        <div className={styles.preSendTime}>
          <div>
            预售结束后
          </div>
          <Item
            label="预售发货时间"
            name="presellDelay"
            noStyle
            rules={
              [
                {
                  required: true,
                  message: '请输入预售发货时间',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value) {
                      return Promise.resolve();
                    }
                    if (value < 2 || value > 15) {
                      return Promise.reject('预售模式下结束后的发货时间为2-15');
                    }
                    return Promise.resolve();
                  },
                }),
              ]
            }
          >
            <InputNumber
              min={2}
              parser={limitNum}
              style={{
                width: 88,
                margin: '0 8px',
              }}
            />
          </Item>
          <div>
            天
          </div>
        </div>
      </Item>
    </>
  );
};
