import { Checkbox, Form, Input, InputNumber, Radio, Select, Space } from 'antd';
import { observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import { limitDecimals } from '../../../../../utils';
import { formLayout } from '../../../../shelvesBase';
import styles from './index.less';
import { phoneReg } from 'egenie-common';

const { Item } = Form;

function disabledDate(current) {
  const now = moment(Date.now())
    .format('YYYY-MM-DD');
  const endTime = moment()
    .add(30, 'days')
    .calendar();
  const jiange = moment(endTime)
    .diff(moment(now), 'days');

  return current && current < moment()
    .endOf('hour') && jiange <= 30;
}

export const LinePriceForm = observer((props) => {
  const { linePriceFormRef } = props.store;
  return (
    <Form
      ref={linePriceFormRef}
      {...formLayout}
      id="linePrice"

    >
      <Item
        label="划线价"
        required
      >
        <Item
          label="划线价"
          name="rebateRatio"
          noStyle
          rules={
            [
              {
                required: true,
                message: '请输入划线价',
              },
            ]
          }
        >
          <InputNumber
            parser={limitDecimals}
            style={{ width: 280 }}
          />
        </Item>
        <span style={{ marginLeft: 8 }}>
          元
        </span>
      </Item>
    </Form>
  );
});
export const ServiceInfoForm = observer((props) => {
  const {
    serviceInfoFormRef,
    freightTemplateList,
    listingTimeMode,
    warrantyService,
    afterSaleOptions,
    afterSaleEnabled,
  } = props.store;
  return (
    <Form
      ref={serviceInfoFormRef}
      {...formLayout}
      id="service"
      initialValues={
        {
          listingTimeMode,
          warrantyService,
        }
      }
    >
      <Item
        label="运费模板"
        name="freightId"
        rules={
          [
            {
              required: true,
              message: '请选择运费模板',
            },
          ]
        }
      >
        <Select
          className={styles.freightTemplateList}
          options={freightTemplateList}
          placeholder="请选择运费模板"
          style={{ width: 280 }}
        />
      </Item>
      {
        afterSaleEnabled ? (
          <Item
            label="售后服务"
            name="supply7dayReturn"
            rules={
              [
                {
                  required: true,
                  message: '请选择售后服务',
                },
              ]
            }
          >
            <Select
              className={styles.freightTemplateList}
              options={afterSaleOptions}
              placeholder=""
              style={{ width: 280 }}
            />
          </Item>
        ) : null
      }
      <Item
        colon={false}
        label="  "
        name="warrantyService"
      >
        <Checkbox.Group className={styles.itemBackground}>
          <Space direction="vertical">
            <Checkbox
              className={styles.strategyCheckbox}
              disabled
              value="1"
            >
              <span className={styles.checkboxText}>
                坏损包退
              </span>
            </Checkbox>
            <Checkbox
              className={styles.strategyCheckbox}
              disabled
              value="2"
            >
              <span className={styles.checkboxText}>
                正品保障
              </span>
            </Checkbox>
            {/* <Checkbox
              className={styles.strategyCheckbox}
              disabled
              value="3"
            >
              7天无理由退换货，该类目商品需支持【7天无理由退换货】服务
            </Checkbox> */}
            <Checkbox
              className={styles.strategyCheckbox}
              disabled
              value="1"
            >
              <span className={styles.checkboxText}>
                订单完成15天内售后保障
              </span>
            </Checkbox>
          </Space>
        </Checkbox.Group>
      </Item>
      <Item
        label="客服电话"
        name="mobile"
        rules={
          [
            {
              required: true,
              message: '请输入客服电话',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value) {
                  return Promise.resolve();
                }
                if (!phoneReg.test(value)) {
                  return Promise.reject('电话格式不正确');
                }
                return Promise.resolve();
              },
            }),
          ]
        }
      >
        <Input style={{ width: 280 }}/>
      </Item>
      <Item
        label="上架时间"
        name="listingTimeMode"
      >
        <Radio.Group>
          <Space>
            <Radio
              value={0}
            >
              立刻上架
            </Radio>
            <Radio
              value={2}
            >
              放入草稿箱
            </Radio>
          </Space>
        </Radio.Group>
      </Item>
    </Form>
  );
});

export * from './deliveryInfoForm';
