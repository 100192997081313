import { action, observable } from 'mobx';
import { nanoid } from 'nanoid';
import { arrayMove } from 'react-sortable-hoc';

export class MainImgsStore {
  @observable public type = 'mainPics';

  @observable public mainPics = [];

  @observable public styleImgLists = [];// 款式库图片库list

  @action public onPictureClick = (src: string): void => {
    const lists = this.mainPics;
    try {
      const index = lists.findIndex((item) => item.url === src);
      if (index === -1) {
        lists.push({
          url: src,
          id: nanoid(),
          imgId: nanoid(),
        });
      } else {
        lists.splice(index, 1);
      }
      this.mainPics = lists;
    } catch (err) {
      console.log(err);
    }
  };

  // 图片排序
  @action public onSortEnd = ({ oldIndex, newIndex }): void => {
    if (oldIndex !== newIndex) {
      this.mainPics = arrayMove(this.mainPics, oldIndex, newIndex);
    }
  };

  @action public onClickDelete = (id): void => {
    this.mainPics = this.mainPics.filter((item, index) => {
      return item.imgId != id;
    });
  };

  @action public clearMainImgs = () => {
    this.mainPics = [];
  };

  @action public writeBackMainImgs = (goodsPicVo) => {
    const mainPics = goodsPicVo?.mainPics || [];
    const arr = [];
    mainPics.forEach((item) => {
      arr.push({
        url: item,
        id: nanoid(),
        imgId: nanoid(),
      });
    });
    this.mainPics = arr;
  };
}
