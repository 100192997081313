import type { BaseData } from 'egenie-utils';
import { request } from 'egenie-utils';

export const formLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 21 },
};

export const charaReg = /[一-鿆]{1}/;// 汉字正则

export const charaRegGlobal = /[一-鿆]{1}/g;// 汉字正则全局

// 计算字符长度
export const calcStrLen = (str: string): number => {
  const strTemp = str.replace(charaRegGlobal, 'rr');
  return strTemp.length;
};

// 截取字符串长度
export const sliceStrLen = (str: string, length: number): string => {
  let len = 0;
  const resArr = [];
  for (let i = 0; i < str.length; i++) {
    if (charaReg.test(str[i])) {
      len += 2;
    } else {
      len++;
    }

    if (len <= length) {
      resArr.push(str[i]);
    }
  }
  return resArr.join('');
};

// 获取图片信息的url
export const getInfoUrl = (url: string) => {
  const urlParse = new URL(url);
  const res = `${urlParse.origin}${urlParse.pathname}?x-oss-process=image/info`;
  return res;
};

export interface IImgInfo {
  width?: string;
  height?: string;
  format?: string;
  size?: string;
  FileSize: FileSize;
  Format: FileSize;
  ImageHeight: FileSize;
  ImageWidth: FileSize;
}

interface FileSize {
  value: string;
}

interface IVideoInfo{
  size: string;
  duration: number;
  capacity: number;
  format: string;
}

// 根据图片url获取图片信息
export const getImageInfo = (url: string): Promise<IImgInfo> => {
  return request<IImgInfo>({
    url: getInfoUrl(url),
    withCredentials: false,
  }).then((res) => {
    if (!res.ImageWidth) {
      res.ImageHeight = { value: res?.height.toString() };
      res.ImageWidth = { value: res?.width.toString() };
      res.Format = { value: res?.format };
      res.FileSize = { value: res?.size.toString() };
    }
    console.log('getImageInfo', res);
    return res;
  });
};

const LEGAL_IMG_FORMAT = [
  'jpeg',
  'jpg',
  'png',
];

// 根据视频id获取视频信息
export const getVideoInfo = (id: string): Promise<IVideoInfo> => {
  return request<BaseData<IVideoInfo>>({
    url: `/api/goodsPic/rest/videoManage/getById?id=${id}`,
    withCredentials: false,
  }).then((res) => {
    return res.data;
  });
};

/**
   * 根据缩放后的尺寸计算图片url
   * @param url 图片原始url
   * @param resizeWidth 缩放宽度
   * @param resizeHeight 缩放高度
   * @param ImageWidth 图片原始宽度
   * @param ImageHeight 图片原始高度
   *
   */
export const getImageUrl = (url: string, resizeWidth: number, resizeHeight: number, ImageWidth, ImageHeight) => {
  let newUrl;

  // 需要缩放的加前缀/不需要则还是使用原始url
  if (resizeWidth !== Number(ImageWidth.value) || resizeHeight !== Number(ImageHeight.value)) {
    newUrl = `${new URL(url).origin}${new URL(url).pathname}?x-oss-process=image/resize,m_pad,w_${Math.floor(resizeWidth)},h_${Math.floor(resizeHeight)},color_FFFFFF,limit_0`;
  } else {
    newUrl = url;
  }

  return newUrl;
};

/**
   * 处理图片格式
   * @param originFormat 图片原始格式
   *
   */
export const dealUnlegalImg = (url: string, originFormat: string) => {
  let newUrl = '';
  if (!LEGAL_IMG_FORMAT.includes(originFormat)) {
    // 如果处理过尺寸直接加格式
    if (url.includes('x-oss-process=image/resize')) {
      newUrl = `${url}/format,jpg`;
    } else {
      // 没有处理过直接对原图进行处理
      newUrl = `${new URL(url).origin}${new URL(url).pathname}?x-oss-process=image/format,jpg`;
    }
  }
  return newUrl || url;
};

/**
   * 处理图片大小（图片超过指定size的话质量80%）
   * @param  图片原始格式
   *
   */
export const dealOversizeImg = (url: string, originFormat: string) => {
  // 图片大小超过限制 需处理
  // 如果已经处理过图片
  if (url.includes('x-oss-process=image')) {
    // 处理过格式的直接加质量处理
    if (originFormat === 'jpg' || url.includes('jpg')) {
      url = `${url}/quality,q_80`;
    } else {
      url = `${url}/format,jpg/quality,q_80`;
    }
  } else {
    // 未处理过处理格式和质量
    url = `${new URL(url).origin}${new URL(url).pathname}?x-oss-process=image/format,jpg/quality,q_80`;
  }
  return url;
};

type ResultProps = IEachProp[];

interface IEachProp{
  required: boolean;
}

/**
   * 处理商品属性（分离必填项和非必填项）
   * @param goodsProps 商品属性列表
   * @return IPropsResult
   */
export const dealGoodsProps = (goodsProps: IEachProp[]): ResultProps => {
  if (!goodsProps || goodsProps.length === 0) {
    return [];
  }

  const requiredProps = goodsProps.filter((el) => el.required);
  const noRequiredProps = goodsProps.filter((el) => !el.required);
  return requiredProps.concat(noRequiredProps);
};

