import { Input, Checkbox, Row, Col } from 'antd';
import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import { SingleUploadImg } from '../../singleUploadImg';
import { AddIcon, ExpandCollapseButton } from '../components';
import styles from './index.less';

export const ColorInfo = observer((props: { sizeColorStore;singleUploadImgStore; }) => {
  const { sizeColorStore, singleUploadImgStore } = props;
  const { selectImg, deleteImg } = singleUploadImgStore;
  const { colorList, addColorDisplay, addColorInput, first10ColorOption, colorExpand, showColorCollapse, toggleExpandColor } = sizeColorStore;

  // 是否只展示前10个颜色（选项数目>10项，并且选项处于收起状态）
  const displayColorList = useMemo(() => {
    return showColorCollapse && !colorExpand ? first10ColorOption : colorList;
  }, [
    showColorCollapse,
    colorExpand,
    colorList,
  ]);
  return (
    <Row>
      <Col
        className={styles.colContent}
        span={3}
      >
        <span className={styles.requiredTip}>
          *
        </span>
        颜色
        <span className={styles.colMao}>
          :
        </span>
      </Col>
      <Col
        span={21}
      >
        <div className={styles.colorWrapper}>
          {
            displayColorList?.map((item, index) => {
              return (
                <EachColor
                  index={index}
                  item={item}
                  key={item.key}
                  singleUploadImgStore={props.singleUploadImgStore}
                  sizeColorStore={props.sizeColorStore}
                />
              );
            })
          }
          {
            addColorDisplay && (
              <div
                className={styles.eachColor}
              >
                <div className={styles.addColorInputWrapper}>
                  <Checkbox checked/>
                  <ColorInput sizeColorStore={props.sizeColorStore}/>
                </div>
                <SingleUploadImg
                  deleteImg={deleteImg}
                  fileList={[]}
                  selectImg={selectImg}
                  type="color"
                />
              </div>
            )
          }
          {/* 添加按钮显示条件：(选项数目 < 10 || colorExpand为true)*/}
          {
            (!showColorCollapse || colorExpand) && (
              <div
                className={styles.eachColor}
              >
                <AddIcon onClick={addColorInput}/>
              </div>
            )
          }
          {/* 展开收起按钮 */}
          {
            showColorCollapse && (
              <ExpandCollapseButton
                flag={colorExpand}
                handleClick={() => {
                  toggleExpandColor(!colorExpand);
                }}
                name="颜色"
              />
            )
          }
        </div>
      </Col>
    </Row>
  );
});

// 每一项颜色
const EachColor: React.FC<{ sizeColorStore; singleUploadImgStore;item;index: number; }> = observer((props) => {
  const { item, index } = props;
  const { handleColorCheckChange, editColor } = props.sizeColorStore;
  const { selectImg, deleteImg } = props.singleUploadImgStore;
  return (
    <div
      className={styles.eachColor}
    >
      <div className={styles.checkWrapper}>
        <Checkbox
          checked={item.checked}
          onChange={(e) => {
            handleColorCheckChange(index, e.target.checked);
          }}
        />
        {
          item.editStatus ? (
            <ColorInput
              index={index}
              sizeColorStore={props.sizeColorStore}
            />
          ) : (
            <EachColorText
              editColor={editColor}
              index={index}
              name={item.name}
            />
          )
        }
      </div>
      <SingleUploadImg
        deleteImg={deleteImg}
        fileList={[
          {
            url: item.url,
            key: item.name,
          },
        ]}
        selectImg={selectImg}
        type="color"
      />
    </div>
  );
});

// 每一项颜色文本
const EachColorText: React.FC<{ name: string;index: number; editColor: (index: number, name: string) => void; }> = (props) => {
  const { name, index, editColor } = props;
  return (
    <div
      className={styles.checkText}
      onClick={() => {
        editColor(index, name);
      }}
    >
      {name}
    </div>
  );
};

// 颜色输入框
const ColorInput: React.FC<{ sizeColorStore; index?: number; }> = observer((props) => {
  const { inputValue, onInputValueChange, onColorInput } = props.sizeColorStore;
  const { index } = props;
  return (
    <Input
      autoFocus
      className={styles.colorSizeInput}
      onBlur={() => {
        onColorInput(index);
      }}
      onChange={(e) => {
        onInputValueChange(e.target.value);
      }}
      onPressEnter={() => {
        onColorInput(index);
      }}
      value={inputValue}
    />
  );
});
