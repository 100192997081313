import { Button, Cascader, Checkbox, Col, Input, message, Modal, Pagination, Row, Spin, Tabs } from 'antd';
import { observer } from 'mobx-react';
import type { ReactNode } from 'react';
import React, { Component } from 'react';
import folderImg from '../../assets/images/folder.png';
import ZeroData from '../../assets/images/zeroData.png';
import { TooltipEllipsis } from '../tooltipEllipsis';
import { CreateFolder } from './createFolder';
import styles from './index.less';
import UploadItems from './uploadItem/index';
import type { UploadStore } from './uploadStore';
import { toJS } from 'mobx';

@observer
export default class ImgOrVideoUpload extends Component<{ store: Partial<UploadStore>; }> {
  render(): ReactNode {
    const {
      visible,
      loading,
      moduleName,
      type,
      activeTab,
      choosedList,
      onChangeTabId,
      onDeleteChoosed,
      createFolderStore,
      onConfirmSelect,
      onSortEnd,
      onlyUpload,
      onlySelect,
      onCancel,
      showCancelButton,
      uploading,
    } = this.props.store;
    const tableItems = [];
    if (!onlyUpload) {
      tableItems.push({
        key: 'manage',
        label: (
          <div className={styles.tabTitle}>
            {moduleName[type]}
            管家
          </div>),
        children: <PicSearch store={this.props.store}/>,
      });
    }
    if (!onlySelect) {
      tableItems.push({
        key: 'upload',
        label: (
          <div className={styles.tabTitle}>
            本地上传
          </div>),
        children: <LocalUpload store={this.props.store}/>,
      });
    }
    return (
      <Modal
        centered
        className={styles.chooseMedia}
        footer={
          [
            showCancelButton && (
              <Button
                key="cancel"
                loading={uploading}
                onClick={onCancel}
                type="ghost"
              >
                取消
              </Button>
            ),
            <Button
              key="ok"
              loading={uploading}
              onClick={onConfirmSelect}
              type="primary"
            >
              确定
            </Button>,
          ]
        }
        maskClosable={false}
        onCancel={uploading ? () => {
          message.info('正在上传，无法关闭！');
        } : onCancel}
        open={visible}
        title={`选择${moduleName[type]}`}
        width="1168px"
      >
        <Spin
          spinning={loading}
          tip="校验文件中..."
        >
          <div className={`${styles.flex} ${styles.h100}`}>
            {/* 图片 | 视频管家 */}
            <Tabs
              activeKey={activeTab}
              className={`${styles.manageWrap} ${styles.plMd} `}
              items={tableItems}
              onChange={onChangeTabId}
            />
            {/* 已选图片 */}
            <div className={`${styles.choosedWrap} ${styles.pdMd} ${styles.flex1}`}>
              <div className={styles.font12}>
                {onlyUpload ? '已上传' : '已选'}
                {moduleName[type]}
                （
                {choosedList.length || 0}
                ）
              </div>
              <div className={`${styles.choosedContent} ${styles.w100}`}>
                {
                  !choosedList.length ? (<Empty/>)
                    : (
                      <UploadItems
                        axis="xy"
                        distance={1}
                        helperClass={styles.sortableHelper}
                        list={choosedList}
                        lockToContainerEdges
                        onDelete={onDeleteChoosed}
                        onSortEnd={onSortEnd}
                        onlyUpload={onlyUpload}
                        type={type}
                      />
                    )
                }
              </div>
            </div>
          </div>
        </Spin>
        <CreateFolder store={createFolderStore}/>
      </Modal>
    );
  }
}

@observer
class PicSearch extends Component<{ store: Partial<UploadStore>; }> {
  render(): ReactNode {
    const {
      catalogueList,
      manageCatalogueId,
      onCatalogueChange,
      onSelectFromPicManage,
      allowSelect,
      searchKey,
      onSearchPic,
      onSearchPicChange,
      list,
      type,
      current,
      total,
      pageSize,
      onPageChange,
      onPageSizeChange,
    } = this.props.store;
    return (
      <div>
        <div className={`${styles.flex} ${styles.faiCenter} ${styles.pbMd}`}>
          <span>
            目录：
          </span>
          <Cascader
            changeOnSelect
            className={styles.catalogue}
            onChange={onCatalogueChange.bind(this, 'manageCatalogueId')}
            options={toJS(catalogueList)}
            value={manageCatalogueId}
          />
          <Input
            className={`${styles.search} ${styles.mlXs}`}
            onChange={onSearchPicChange}
            onPressEnter={onSearchPic}
            placeholder="输入名称查询，enter查询"
            value={searchKey}
          />
        </div>
        <div className={styles.pr6}>
          <Row
            className={styles.manageContentHeight}
            gutter={[
              8,
              8,
            ]}
          >
            {
              !list.length ? (
                <Empty/>
              )
                : list.map((el) => {
                  const {
                    id,
                    url,
                    name,
                    isChoose,
                  } = el;
                  return (
                    <Col

                      // className={styles.picItem}
                      key={id}
                    >
                      {/* 下面选择用el.type 而不是type原因是 type在store中被视频和图片占用了 */}
                      <div
                        className={`${styles.imgWrap} ${!allowSelect ? styles.disabledSelect : ''}`}
                        onClick={onSelectFromPicManage.bind(this, id, el.type)}
                      >
                        <div className={`${styles.imgContent} ${styles.poRelative} ${styles.pdXs}`}>
                          {
                            el.type === 1 ? (
                              <img
                                className={styles.img}
                                src={folderImg}
                              />
                            ) : type === 'picManage' ? (
                              <img
                                className={styles.img}
                                src={url}
                              />
                            ) : (
                              <video
                                className={styles.img}
                                controls
                                muted
                                preload="meta"
                                src={`${url}#t=0.1`}
                              />
                            )
                          }
                          {el.type !== 1 && (
                            <Checkbox
                              checked={isChoose}
                              className={`${styles.chooseRadio} ${styles.poAbsolute}`}
                              disabled={!allowSelect}
                            />
                          )}
                        </div>
                        <TooltipEllipsis param={{ title: name }}>
                          <div className={`${styles.w100} ${styles.fontSm} ${styles.mtXss} ${styles.textEllipsis} ${styles.textCenter}`}>
                            {name}
                          </div>
                        </TooltipEllipsis>
                      </div>
                    </Col>
                  );
                })
            }
          </Row>
          <Pagination
            className={styles.pagination}
            current={current}
            defaultPageSize={30}
            onChange={onPageChange}
            onShowSizeChange={onPageSizeChange}
            pageSize={pageSize}
            pageSizeOptions={[
              '10',
              '20',
              '30',
              '40',
              '50',
            ]}
            total={total}
          />
        </div>

      </div>
    );
  }
}

@observer
class LocalUpload extends Component<{ store: Partial<UploadStore>; }> {
  render(): ReactNode {
    const {
      catalogueList,
      uploadCatalogueId,
      onCatalogueChange,
      uploadTab,
      moduleName,
      type,
      allowSelect,
      onChangeUploadChange,
      multiple,
      createFolderStore,
      onSelectFile,
      onJumpToManage,
      onlyUpload,
    } = this.props.store;
    return (
      <div className={styles.prMd}>
        <div className={`${styles.flex} ${styles.faiCenter} ${styles.fjcsBetween} ${styles.pbMd}`}>
          <div className={`${styles.flex} ${styles.faiCenter}`}>
            <span>
              目录：
            </span>
            <Cascader
              changeOnSelect
              className={styles.catalogue}
              onChange={onCatalogueChange.bind(this, 'uploadCatalogueId')}
              options={toJS(catalogueList)}
              value={uploadCatalogueId}
            />
            <Button
              className={styles.mlXs}
              ghost
              onClick={createFolderStore.handleOpen}
              type="primary"
            >
              新建文件夹
            </Button>
          </div>
          {!onlyUpload && (
            <a
              onClick={onJumpToManage}
              type="link"
            >
              前往
              {moduleName[type]}
              管家
            </a>
          )}
        </div>
        <div className={`${styles.contentHeight} ${styles.bgf3} ${styles.flex} ${styles.fdColumn} ${styles.faiCenter}`}>
          <i
            className={uploadTab[type].icon}
            style={{
              fontSize: '120px',
              color: '#C5C3CB',
              marginTop: '80px',
            }}
          />
          <div className={`${styles.clText} ${styles.fontSm} ${styles.font400} ${styles.mtMd}`}>
            {uploadTab[type].desc}
          </div>
          <div className={`${styles.flex} ${styles.uploadBtnGroup}`}>
            <Button
              className={`${styles.mt50} ${styles.mr10} `}
              disabled={!allowSelect}
              onClick={onSelectFile}
              type="primary"
            >
              上传
              {moduleName[type]}
              <input
                accept="image/jpeg,image/jpg,image/png,video/mp4"
                className={styles.compressInput}
                id="multipleSelectFile"
                multiple={multiple}
                onChange={onChangeUploadChange}
                type="file"
              />
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const Empty = () => {
  return (
    <div className={`${styles.flex} ${styles.fjcCenter} ${styles.faiCenter} ${styles.w100} ${styles.h100}`}>
      <img
        className={styles.empty}
        src={ZeroData}
      />
    </div>
  );
};
