import { Cascader, Col, Row, Tag } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import styles from './index.less';
import type { CategorySelectorStore } from './store';
import { toJS } from 'mobx';

const CategoryCom: React.FC<{ store: CategorySelectorStore; }> = observer((props) => {
  const {
    loadData,
    onItemChange,
    options,
    autoKey,
    selectTxt,
    selectedValue,
  } = props.store;
  return (
    <div
      className={styles.categoryComWrap}
    >
      <div
        className={styles.cascaderWrap}
        id="hasSelectCategoryWrap"
      >
        <Cascader
          changeOnSelect
          getPopupContainer={() => document.getElementById('hasSelectCategoryWrap')}
          key={autoKey} // 自动选中类目必须有key 才能渲染
          loadData={loadData}
          onChange={onItemChange}
          open
          options={toJS(options)}
          placeholder="请选择"
          popupClassName={styles.customCascader}
          value={selectedValue}
        >
          <div
            className={`${styles.hasSelectCategoryWrap} ${styles.mt15}`}
          >
            已选类目：
            {
              selectTxt
            }
          </div>
        </Cascader>
      </div>
    </div>
  );
});

export const CategorySelector = observer(((props: { store; }) => {
  const {
    originCategoryName,
    historyCategoryList,
    options,
    loadCurrentCategory,
  } = props.store;
  return (
    <Row>
      <Col
        className={styles.colTitle}
        span={4}
      >
        原商品类目：
      </Col>
      <Col
        className={`${styles.colTitle} ${styles.colValue}`}
        span={20}
      >
        {originCategoryName ? originCategoryName.split(',')
          .join('>') : ''}
      </Col>
      <Col
        className={styles.colTitle}
        id="categoryConfirm"
        span={4}
        style={{
          position: 'relative',
          top: '5px',
        }}
      >
        确认商品类目：
      </Col>
      <Col
        className={`${styles.colTitle} ${styles.colValue} `}
        span={20}
      >
        <div className={styles.categoryTips}>
          <span className={styles.red}>
            *
          </span>
          请确保您选择的类目与商品实际类目一致，否则存在上架失败的风险。
        </div>
        <div className={styles.mt15}>
          最近使用：
          {options && options.length > 0 && historyCategoryList && historyCategoryList.map(({
            categoryName,
            ids,
            names,
          }, index) => {
            return (
              <Tag
                className={styles.tag}
                key={ids.toString()}
                onClick={() => loadCurrentCategory(ids, names)}
              >
                {categoryName}
              </Tag>
            );
          })}
        </div>
      </Col>
      <Col
        className={styles.colTitle}
        span={4}
      />
      <Col
        className={`${styles.colTitle} ${styles.colValue} ${styles.allCategoryWrap}`}
        span={20}
        style={{ marginTop: 0 }}
      >
        <CategoryCom store={props.store}/>
      </Col>
    </Row>
  );
}));

