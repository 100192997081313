import { message } from 'antd';
import type { BaseData } from 'egenie-common';
import { request } from 'egenie-utils';
import { action, flow, observable, runInAction } from 'mobx';
import { nanoid } from 'nanoid';
import { categoryRequest, URL_DICT, URL_MATCH_DICT, URL_MATCH_DICT_BATCH } from './constant';
import type { HistoryCategory, ICategoryNode, ICategoryOption, IMatchCategory, OriginCategory } from './interface';

export class CategorySelectorStore {
  @observable public gmsGoodsId = '';

  @observable public platformType = '';

  @observable public shopId = '';

  @observable public originCategoryName = '';// 原商品类目

  @observable public historyCategoryList: HistoryCategory[] = [];// 历史类目

  @observable public options: ICategoryOption[];// 类目树形结构

  @observable public selectTxt = '';// 已选类目

  @observable public selectedValue: number[] = [];// 选中类目

  @observable public autoKey = '';// 自动加载类目赋给cascader的key

  @observable public canNext = false; // 是否可以进行下一步

  /**
   * 初始化数据(外部调用)
   * @params gmsGoodsId 商品id
   * @params platformType 平台类型
   * @params shopId 店铺id
   * @params batchShelvesParams 批量上架传的参数
   */
  public initData = flow(function* (gmsGoodsId: string, platformType: string, shopId: string, batchShelvesParams?: {
    gmsStyleIds: number[];
    shopIds: number[];
  }) {
    this.shopId = shopId;
    this.platformType = platformType;

    // 获取原商品类目(所有平台通用)（区分批量上架还是单个上架）
    if (batchShelvesParams) {
      const { data } = yield request<BaseData<string>>({
        url: '/api/gms/pc/style/getStyleCategoryFullName',
        method: 'POST',
        data: { gmsStyleIds: batchShelvesParams.gmsStyleIds },
      });
      this.originCategoryName = data;
    } else {
      const { data } = yield request<BaseData<OriginCategory>>({
        url: '/api/gms/goods/getGoodsCategory',
        method: 'POST',
        data: { gmsGoodsId },
      });
      this.originCategoryName = data.goodsCategoryFullName;
    }

    // 获取历史类目（所有平台通用）
    const historyCategoryRes = yield request<BaseData<HistoryCategory[]>>({
      url: '/api/gms/pc/common/category/history',
      method: 'POST',
      data: { platformType },
    });

    this.historyCategoryList = historyCategoryRes.data;

    // 一级商品类目
    // 天猫或淘宝
    if (this.platformType == '-100' || this.platformType == '1') {
      const firstLevelCategoryRes = yield categoryRequest('/api/gms/goods/platform/tmall/queryItemCatsAuthorize', 'POST', this.shopId);
      this.options = this.mapCategoryOptions(firstLevelCategoryRes.data);
    } else {
      const firstLevelCategoryRes = yield categoryRequest(URL_DICT[platformType], 'POST', this.shopId);
      this.options = this.mapCategoryOptions(firstLevelCategoryRes.data);
    }

    // 自动勾选类目（区分批量上架还是单个上架）
    let matchItemsRes: BaseData<IMatchCategory[]> = {} as any;
    if (batchShelvesParams) {
      matchItemsRes = yield request<BaseData<IMatchCategory[]>>({
        url: URL_MATCH_DICT_BATCH[this.platformType],
        method: 'POST',
        data: {
          gmsStyleIds: batchShelvesParams.gmsStyleIds,
          shopIds: batchShelvesParams.shopIds,
        },
      });
    } else {
      matchItemsRes = yield request<BaseData<IMatchCategory[]>>({
        url: URL_MATCH_DICT[this.platformType],
        method: 'POST',
        data: { gmsGoodsId },
      });
    }

    const ids = matchItemsRes.data.map((item) => item.id);
    const names = matchItemsRes.data.map((item) => item.categoryName);
    this.loadCurrentCategory(ids, names);
  });

  // 点击类目加载数据
  @action public loadData = async(selectedOptions) => {
    const targetOption = selectedOptions[selectedOptions.length - 1];

    targetOption.loading = true;

    // 天猫或淘宝
    if (this.platformType == '-100' || this.platformType == '1') {
      const res = await categoryRequest(
        URL_DICT[this.platformType],
        'GET',
        this.shopId,
        targetOption.value
      );

      runInAction(() => {
        this.optionIterator(this.options, targetOption, this.mapCategoryOptions(res.data));
        this.options = [...this.options];
      });
    } else {
      const res = await categoryRequest(
        URL_DICT[this.platformType],
        'POST',
        this.shopId,
        targetOption.value
      );

      runInAction(() => {
        this.optionIterator(this.options, targetOption, this.mapCategoryOptions(res.data));
        this.options = [...this.options];
      });
    }
  };

  private optionIterator = (list: any, target: any, children: any) => {
    for (let i = 0; i < list.length; i++) {
      const item = list[i];
      if (item.children && item.children.length > 0) {
        this.optionIterator(item.children, target, children);
      } else if (item.value === target.value) {
        item.loading = false;
        item.children = children;
        break;
      }
    }
  };

  // 选择项变化(更新已选类目)
  @action public onItemChange = (ids: number[], categoryList: ICategoryOption[]) => {
    this.selectTxt = categoryList.map((item) => item.label)
      .join('>');
    this.selectedValue = ids;

    const lastCategoryItem = categoryList[categoryList.length - 1];
    this.canNext = lastCategoryItem.isLeaf;
  };

  // 自动加载现有的选项
  @action public loadCurrentCategory = async(ids: number[], names: string[]) => {
    const copyIds = ids.slice();
    let lastOptions;
    while (copyIds.length > 0) {
      const eachId = copyIds.shift();
      if (!lastOptions) {
        lastOptions = this.options;
      }
      try {
        const eachOption = lastOptions.filter((item) => item.value === eachId);
        if (!eachOption[0].isLeaf) {
          await this.loadData(eachOption);
          lastOptions = eachOption[0].children;
        } else {
          this.canNext = true;
        }
      } catch (e) {
        message.warn('该类目不存在');
        return;
      }
    }
    runInAction(() => {
      this.selectedValue = ids;
      if (names) {
        this.selectTxt = names.join('>');
      }
      this.autoKey = nanoid(10);
    });
  };

  // 处理类目选项
  private mapCategoryOptions = (data: ICategoryNode[]) => {
    return data.map((item) => ({
      label: item.categoryName || item.name,
      value: item.id,
      isLeaf: item.leaf,
    }));
  };
}
