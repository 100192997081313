import { Form } from 'antd';
import { EgGrid } from 'egenie-utils';
import { observer } from 'mobx-react';
import React from 'react';
import { SkuAlert, ColorInfo, SizeInfo } from '../../../../shelvesBase';
import { TimeVarSelect } from './timeVar';// 时效规格

const { Item } = Form;

export const SizeAndColorInfo = observer((props: { sizeColorStore;singleUploadImgStore; }) => {
  const { singleUploadImgStore, sizeColorStore } = props;
  const { productSaleSpecGridModel, hasTimeVar } = sizeColorStore;
  return (
    <>
      <ColorInfo
        singleUploadImgStore={singleUploadImgStore}
        sizeColorStore={sizeColorStore}
      />
      <SizeInfo sizeColorStore={sizeColorStore}/>
      {
        hasTimeVar ? <TimeVarSelect sizeColorStore={sizeColorStore}/> : null
      }
      <div id="skuTable">
        <Item
          label="商品销售规格"
          labelCol={{ span: 3 }}
          required
          wrapperCol={{ span: 21 }}
        >
          <SkuAlert/>
          <div style={{ height: '268px' }}>
            <EgGrid store={productSaleSpecGridModel}/>
          </div>
        </Item>
      </div>
    </>
  );
});

