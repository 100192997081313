import { CloseOutlined } from '@ant-design/icons';
import { Spin, Row, Col } from 'antd';
import { observer } from 'mobx-react';
import type { ReactElement } from 'react';
import React from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import Delete from '../../../assets/images/delete.png';
import styles from './index.less';

const UrlContent = observer(({ url, type }) => {
  return (type === 'picManage' ? (
    <img
      className={styles.img}
      src={url}
    />
  ) : (
    <video
      className={styles.img}
      controls
      muted
      src={url}
    />
  ));
});

const LoadingContent = observer(({ progress }) => {
  return (
    <div className={`${styles.flex} ${styles.fjcCenter} ${styles.faiCenter} ${styles.fdColumn} ${styles.w100} ${styles.h100} ${styles.bgf0}`}>
      <progress
        className={styles.progress}
        max="1"
        value={progress}
      />
    </div>
  );
});

const ErrorContent = observer(() => {
  return (
    <div className={`${styles.flex} ${styles.fjcCenter} ${styles.faiCenter} ${styles.fdColumn} ${styles.w100} ${styles.h100} ${styles.bgf0}`}>
      <div>
        上传失败，重试
      </div>
      <i
        className="icon-cxsc"
      />
    </div>
  );
});

const SortableItem = SortableElement(({ item, onDelete, type, onlyUpload }) => {
  const content = (item): ReactElement => {
    const { progress } = item;
    switch (item.type) {
      case 'url':
        return (
          <UrlContent
            type={type}
            url={item.url}
          />
        );
      case 'loading':
        return <LoadingContent progress={progress}/>;
      case 'error':
        return <ErrorContent/>;
      default:
        return null;
    }
  };
  return (
    <Col key={item.id}>
      <div className={`${styles.imgContent} ${styles.poRelative} ${styles.pdXs}`}>
        {content(item)}
        {!onlyUpload && (
          <div
            className={`${styles.deleteWrap} ${styles.poAbsolute}`}
            onClick={onDelete.bind(this, item.id)}
          >
            <img
              className={`${styles.deleteWrap} ${styles.poAbsolute}`}
              src={Delete}
              style={item.type === 'loading' ? { display: 'none' } : {}}
            />
          </div>
        )}
      </div>
    </Col>
  );
});

const UploadItems = SortableContainer((props): ReactElement => {
  const { list, onDelete, type, onlyUpload } = props;
  return (
    <Row gutter={[
      8,
      8,
    ]}
    >
      {
        list.map((el, index) => {
          return (
            <SortableItem
              index={index}
              item={el}
              key={el.id}
              onDelete={onDelete}
              onlyUpload={onlyUpload}
              type={type}
            />
          );
        })
      }
    </Row>
  );
});

export default UploadItems;
