import { debounce } from 'lodash';
import { observable, action, toJS } from 'mobx';
import shortid from 'shortid';
import type { IMaterialDict, IObj } from '../../interface';

export class MaterialStore {
  constructor(options) {
    this.parent = options;
    this.onChange = debounce(this.onChange, 500);
  }

  @observable public parent;

  @observable public error = false;

  @observable public errorMsg = '';

  @observable public isMaterialRequired = false;

  @observable public materialDict: IMaterialDict[] = [];

  @observable public materialDictNoInput = [];

  @observable public materialLists = [
    {
      id: shortid.generate(),
      value: undefined,
      needContentNumber: true,
      inputValue: '',
    },
  ];

  // 添加材质成分
  @action public addMaterial = () => {
    if (this.materialLists.length < this.materialDict.length) {
      this.error = false;
      this.errorMsg = '';
      const lenth = this.materialLists.length;
      if (this.materialLists[lenth - 1].value) {
        if (this.materialLists[lenth - 1].needContentNumber) {
          if (this.materialLists[lenth - 1].inputValue) {
            this.materialLists.push({
              id: shortid.generate(),
              value: null,
              needContentNumber: true,
              inputValue: '',
            });
          } else {
            this.error = true;
            this.errorMsg = '请完善材质成分';
          }
        } else {
          this.materialLists.push({
            id: shortid.generate(),
            value: null,
            needContentNumber: true,
            inputValue: '',
          });
        }
      } else {
        this.error = true;
        this.errorMsg = '请完善材质成分';
      }
    }
  };

  // 删除材质成分
  @action public deleteItem = (id, index) => {
    const { materialLists } = this;
    materialLists.splice(index, 1);
    this.validMaterial();
  };

  @action public onChange = (e, id, type) => {
    const value = type === 'inputValue' ? typeof e === 'string' ? 0 : this.getNum(e) : e;
    const { item, materialLists } = this.findItem(id);
    if (item && type === 'value') {
      item[type] = value;
      item.needContentNumber = !(this.materialDictNoInput.find((el) => el === e));
      this.materialLists = materialLists;
    }
  };

  @action public onBlur = (e: number, id: number, type: string): void => {
    this.error = false;
    const value = type === 'inputValue' ? typeof e === 'string' ? 0 : this.getNum(e) : e;
    const { item } = this.findItem(id);
    if (item) {
      item[type] = value;
      item.needContentNumber = !(this.materialDictNoInput.find((el) => el === e));
    }

    this.validMaterial();
  };

  @action public getNum = (num): number => {
    if (num > 100) {
      return 100;
    } else if (num < 0) {
      return 0;
    } else {
      return num;
    }
  };

  @action public findItem = (id) => {
    const materialLists = this.materialLists;
    const item = materialLists.find((el) => el.id == id);
    return {
      item,
      materialLists,
    };
  };

  @action public getMaterialLists = (type): any => {
    // if (type === 'next') {
    //   if (this.validMaterial()) {
    //     return this.getMaterialListsFn();
    //   } else {
    //     return [];
    //   }
    // } else {
    //   return this.getMaterialListsFn();
    // }
    const arr = [];
    if (this.materialLists.length > 0 && this.materialLists[0].value) {
      this.materialLists.map((item) => {
        const obj: IObj = {};
        obj.name = item.value;
        obj.needContentNumber = item.needContentNumber;
        if (item.needContentNumber) {
          obj.percent = item.inputValue || null;
        }
        arr.push(obj);
      });
    }
    return arr;
  };

  // 获取填写的材质成分信息
  @action public getMaterialListsFn = () => {
    const arr = [];
    if (this.materialLists.length > 0 && this.materialLists[0].value) {
      this.materialLists.map((item) => {
        const obj: IObj = {};
        obj.name = item.value;
        if (item.needContentNumber) {
          obj.percent = item.inputValue || null;
        }
        arr.push(obj);
      });
    }
    return arr;
  };

  // 校验材质成分是否填写完善 以及 需要填写百分比的和是否为100
  @action public validMaterial = (): boolean => {
    this.errorMsg = '';
    if (this.materialLists.length === 0) {
      return false;
    }
    const errorList = [];
    const needNumArr = [];
    this.materialLists.forEach((item) => {
      if (!item.value || (item.needContentNumber && !item.inputValue)) {
        errorList.push(item);
      }
      if (item.needContentNumber) {
        needNumArr.push(item);
      }
    });
    if (errorList.length > 0) {
      this.error = true;
      this.errorMsg = '请完善材质成分';
      return false;
    }
    if (needNumArr.length > 0 && needNumArr.reduce((total, n) => total + Number(n.inputValue), 0) !== 100) {
      this.error = true;
      this.errorMsg = '材质的含量值总和必须为100%';
      return false;
    }

    this.error = false;
    return true;
  };

  // 回写材质成分
  @action public writebackMaterialProps = (materialLists): any => {
    if (materialLists.length === 0) {
      this.materialLists = [
        {
          id: shortid.generate(),
          value: undefined,
          needContentNumber: true,
          inputValue: '',
        },
      ];
      return;
    }
    this.materialLists = materialLists && materialLists.map((item) => {
      const { name, percent, needContentNumber } = item;
      return {
        id: shortid.generate(),
        value: name,
        inputValue: percent,
        needContentNumber,
      };
    });
  };
}

