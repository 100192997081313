import { action, observable } from 'mobx';
import { nanoid } from 'nanoid';
import { arrayMove } from 'react-sortable-hoc';

export class ProductInfoStore {
  constructor(options) {
    this.parent = options;
  }

  @observable public parent;

  @observable public imgs = [];

  @observable public type = 'productInfo';

  @observable public details = '';

  @action
  public setDetails = (details: string) => {
    this.details = details;
  };

  // 图片排序
  @action public onSortEnd = ({ oldIndex, newIndex }): void => {
    if (oldIndex !== newIndex) {
      this.imgs = arrayMove(this.imgs, oldIndex, newIndex);
    }
  };

  @action public onClickDelete = (id): void => {
    this.imgs = this.imgs.filter((item, index) => {
      return item.imgId != id;
    });
  };

  @action public writeBackDetailImgs = (goodsPicVo) => {
    const detailPics = goodsPicVo?.detailPics || [];
    const arr = [];
    detailPics.forEach((item) => {
      arr.push({
        url: item,
        id: nanoid(),
        imgId: nanoid(),
      });
    });
    this.imgs = arr;
  };

  @action public setType = (type) => {
    this.parent.singleUploadImgStore.selectImg(type);
  };

  @action public onOpen = () => {
    this.parent.singleUploadImgStore.uploadStoreImg.onOpen();
  };
}
