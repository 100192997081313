import { LeftOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { Button, Cascader, Input, InputNumber, Select, Tag, Row, Col } from 'antd';
import { debounce } from 'lodash';
import { observer } from 'mobx-react';
import React from 'react';
import shortid from 'shortid';
import styles from './index.less';

const { Search } = Input;
const { Option } = Select;

interface IMaterialProps{
  store;
  labelStyle?: React.CSSProperties;
  selectStyle?: React.CSSProperties;
  inputNumberCustom?: boolean;
}
export const MaterialProps = observer((props: IMaterialProps) => {
  const { materialLists, onChange, onBlur, deleteItem, materialDict, addMaterial, error, errorMsg, materialDictNoInput, isMaterialRequired } = props.store;
  return (
    <div
      className={`${styles.materialPropsWrap} ${!props.labelStyle && 'ant-col-12'}`}
      style={{ marginBottom: !props.labelStyle && isMaterialRequired ? '24px' : 0 }}
    >
      {
        materialLists.length && materialLists.map((item, index) => {
          const { id, value, inputValue } = item;
          return (
            <div
              className={styles.materialProps}
              key={item.name || shortid.generate()}
              style={{ marginTop: index > 0 ? 16 : 0 }}
            >
              <div
                className={`${styles.title} ant-col-6`}
                style={props.labelStyle && props.labelStyle}
              >
                {index === 0 ? (
                  <span>
                    {isMaterialRequired && (
                      <span style={{ color: '#f2270a' }}>
                        *
                      </span>
                    )}
                    材质成分：
                  </span>
                ) : ''}
              </div>
              <div
                className="ant-col ant-col-7"
                style={props.selectStyle && props.selectStyle}
              >
                <Select
                  allowClear
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  onChange={(e) => {
                    onChange(e, id, 'value');
                  }}
                  optionFilterProp="children"
                  placeholder="请选择材质"
                  showSearch
                  style={{ width: '100%' }}
                  value={value}
                >
                  {materialDict.map((k) => {
                    const { name } = k;
                    return (
                      <Option
                        disabled={materialLists.find((el) => el.value === name)}
                        key={name}
                        value={name}
                      >
                        {name}
                      </Option>
                    );
                  })}
                </Select>
              </div>
              {!(materialDictNoInput.find((el) => el === value)) && (
                <div className={`${styles.inputNum} ${props.labelStyle ? 'ant-col-4' : 'ant-col-5'}`}>
                  <InputNumber
                    max={100}
                    min={0}
                    onBlur={(e) => {
                      const { value } = e.target;
                      const num = Number(value);
                      onBlur(num, id, 'inputValue');
                    }}
                    onChange={(e) => {
                      onChange(e, id, 'inputValue');
                    }}

                    placeholder="输入含量"
                    style={{
                      // border: (error && !inputValue) ? '1px solid #f2270a' : 'none',
                      width: props.inputNumberCustom && '100%',
                    }}
                    value={inputValue}
                  />
                  <div className={styles.percent}>
                    %
                  </div>
                </div>
              )}
              {materialLists.length > 1 && (
                <div
                  className={`${styles.deleteItem} ant-col-2`}
                  onClick={() => {
                    deleteItem(id, index);
                  }}
                >
                  <MinusCircleOutlined style={{
                    fontSize: 24,
                    color: '#999BA4',
                  }}
                  />
                </div>
              )}
            </div>
          );
        })
      }
      {error && (
        <Col className={styles.errorWrap}>
          <Col
            span={6}
            style={props.labelStyle && props.labelStyle}
          >
            {' '}
          </Col>
          <Col
            className={`${styles.errorInfo} animate__animated animate__fadeIn`}
            span={12}
          >
            {errorMsg || '请填写材质成分'}
          </Col>
        </Col>
      )}
      <div
        className={`${styles.addMaterial} ${props.labelStyle ? 'ant-col-16' : 'ant-col-12'}`}
        onClick={addMaterial}
        style={{ marginLeft: '25%' }}
      >
        + 添加材质
      </div>
    </div>
  );
});

