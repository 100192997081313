import { observer } from 'mobx-react';
import React from 'react';
import ImgOrVideoUpload from '../../../../utils/upLoad';
import { ProfitInput, FootPart, ContentWrap, AnchorCustom } from '../../../shelvesBase';
import BaseinfoForm from './baseinfo';
import { ImgTextInfo } from './imgTextInfo';
import styles from './index.less';
import { DeliveryInfoForm, ServiceInfoForm } from './pageFormInfo';
import { SizeAndColorInfo } from './saleInfo/sizeAndColorInfo';
import { ProductQuality } from './productQuality';

const StepTwo = observer((props) => {
  const { submitInfo, saveInfo } = props.stepTwoStore;
  const { sizeColorStore, singleUploadImgStore, mainImgsStore, productInfoStore } = props;
  return (
    <div>
      <AnchorCustom store={props.anchorStore}/>
      <div
        className={styles.stepTwoInfoWrap}
      >
        <BaseinfoForm
          store={props.baseinfoStore}
        />
        <ContentWrap
          id="goodsDec"
          text="图文信息"
        >
          <ImgTextInfo
            mainImgsStore={mainImgsStore}
            productInfoStore={productInfoStore}
            singleUploadImgStore={singleUploadImgStore}
          />
        </ContentWrap>
        <ContentWrap
          id="saleInfo"
          text="价格库存"
        >
          <DeliveryInfoForm store={props.pageFormInfoStore}/>
          <SizeAndColorInfo
            singleUploadImgStore={singleUploadImgStore}
            sizeColorStore={sizeColorStore}
          />
        </ContentWrap>
        <ContentWrap
          id="productQuality"
          text="商品资质"
        >
          <ProductQuality
            store={singleUploadImgStore}
          />
        </ContentWrap>
        <ContentWrap
          id="afterSaleAndOther"
          text="服务与履约"
        >
          <ServiceInfoForm store={props.pageFormInfoStore}/>
        </ContentWrap>
      </div>
      <FootPart
        save={saveInfo}
        submitInfo={submitInfo}
      >
        <ProfitInput handleProfitAdd={sizeColorStore.handleSalePriceAddProfit}/>
      </FootPart>
      <ImgOrVideoUpload store={props.singleUploadImgStore.uploadStoreImg}/>
    </div>
  );
});

export default StepTwo;

