import { InfoCircleOutlined } from '@ant-design/icons';
import { Alert } from 'antd';
import React from 'react';
import styles from './index.less';

export const SkuAlert: React.FC = () => {
  return (
    <Alert
      banner
      className={styles.alert}
      icon={(<InfoCircleOutlined/>)}
      message="不建议修改SKU编码或新增自定义颜色尺码，否则将影响代发订单的正常履约！"
      showIcon
      type="error"
    />
  );
};
