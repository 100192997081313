import { InputNumber, Button, Divider, message } from 'antd';
import React, { useState } from 'react';
import styles from './index.less';

interface ProfitProps{
  handleProfitAdd: (price: number) => void;
}

/**
   * 上架第二步利润输入框组件（淘宝 天猫 抖音用）
   * @param handleProfitAdd 根据利润计算售价
  */
export const ProfitInput: React.FC<ProfitProps> = (props) => {
  const [
    price,
    setPrice,
  ] = useState();

  const handleChange = (val) => {
    setPrice(val);
  };
  const handleConfirm = () => {
    if (!price) {
      message.error('请输入利润金额');
      return;
    }
    props.handleProfitAdd(price);
    setPrice(null);
  };
  return (
    <div className={styles.profitWrapper}>
      <span>
        售价=进货价+
      </span>
      <InputNumber
        min={0}
        onChange={handleChange}
        placeholder="请输入利润(元)"
        precision={2}
        step={1}
        value={price}
        width={140}
      />
      <Button
        className={styles.buttonWrapper}
        onClick={handleConfirm}
      >
        确定
      </Button>
      <Divider
        className={styles.divider}
        type="vertical"
      />
    </div>
  );
};
