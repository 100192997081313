import { message } from 'antd';
import type { BaseData } from 'egenie-utils';
import { request } from 'egenie-utils';
import { observable, action, toJS } from 'mobx';
import moment from 'moment';
import { api } from '../../../../utils';
import { scrollToAnchor, escape2Html } from '../../../../utils/tools';
import type { IObj } from '../../interface';
import { rulesValueDict } from './pageFormInfo/constant';

class Store {
  constructor(options) {
    this.parent = options;
  }

  @observable public parent;

  @observable public loading = false;

  @observable public submitParams = {};

  @action public valideData = (): any => {
    return new Promise((resolve, reject) => {
      const { pageFormInfoStore, anchorStore, baseinfoStore, salePropsStore, colorSchemeStore, mainImgsStore, productInfoStore } = this.parent;
      anchorStore.getIncomplete();
      if (mainImgsStore.mainPics.length === 0) {
        message.error('请上传主图');
        scrollToAnchor('mainImgs');
        return resolve(false);
      }
      if (productInfoStore.imgs.length === 0) {
        message.error('请上传详情图');
        scrollToAnchor('productInfo');
        return resolve(false);
      }
      const { validateFields } = baseinfoStore.baseinfoFormRef.current;
      const colorArrTmp = colorSchemeStore.colorArr.filter((item) => item.name);

      validateFields().then(() => {
        pageFormInfoStore.deliveryInfoFormRef.current.validateFields().then(() => {
          const colorImgs = this.parent.salePropsStore.getColorImgs();
          if (colorArrTmp.length > 0 && colorImgs.filter((item) => item.url).length !== colorArrTmp.length) {
            message.error('请上传颜色图');
            scrollToAnchor('colorScheme');
            return resolve(false);
          }

          // pageFormInfoStore.linePriceFormRef.current.validateFields(() => {
          pageFormInfoStore.serviceInfoFormRef.current.validateFields().then(() => {
            const validSkuInfo = salePropsStore.validSkuInfo();
            if (colorArrTmp.length > 0 && validSkuInfo.length > 0) {
              scrollToAnchor('skuTableInfo');
              return resolve(false);
            }
            if (colorSchemeStore.colorError) {
              scrollToAnchor('colorScheme');
              return resolve(false);
            }
            return resolve(true);
          })
            .catch(() => {
              scrollToAnchor('afterSaleAndOther');
              return resolve(false);
            });

          // }).catch(() => {
          //   scrollToAnchor('linePrice');
          //   return resolve(false);
          // });
        })
          .catch(() => {
            scrollToAnchor('saleInfo');
            return resolve(false);
          });
      })
        .catch(() => {
          scrollToAnchor('baseinfo');
          return resolve(false);
        });
    });
  };

  @action public releaseParams = (type): any => {
    const params: IObj = { gmsGoodsId: this.parent.gmsGoodsId };
    const baseinfoFormData = this.parent.baseinfoStore.getBaseInfo();
    const { goodsName, recommendRemark, payType, goodsProps, sizeInfoTemplateId } = baseinfoFormData;
    const { deliveryInfoFormRef, serviceInfoFormRef } = this.parent.pageFormInfoStore;
    const serviceInfo = serviceInfoFormRef.current.getFieldsValue();
    const { freightId, supply7dayReturn, mobile, listingTimeMode } = serviceInfo;

    const { categoryId, categoryName, categoryIds } = this.parent;
    const deliveryInfo = deliveryInfoFormRef.current.getFieldsValue();
    const { presellEndTime, deliveryDelayDay, deliveryMode } = deliveryInfo;
    const { presellType, presellConfigLevel } = rulesValueDict[deliveryMode];

    if (deliveryInfo.presellDelay === undefined) {
      deliveryInfo.presellDelay = 0;
    }

    // 质检报告
    const qualityInspectionReports = this.parent.singleUploadImgStore.qualityReportList?.map((item) => item.url).filter((v) => v);

    const platformExtends: IObj = {
      ...deliveryInfo,
      recommendRemark,
      goodsProps,
      categoryLeafId: categoryId,
      payType,
      mobile,
      commit: listingTimeMode === 0,
      freightId,
      supply7dayReturn,
      presellType,
      presellConfigLevel,
      deliveryDelayDay: presellType !== 0 ? 2 : deliveryDelayDay,
      presellEndTime: (presellType === 1 && presellEndTime) ? moment(presellEndTime, 'YYYY-MM-DD HH:mm:ss') : null,
      sizeInfoTemplateId,
      qualityInspectionReports,

      // presellEndTime: (presellType === 1 && presellEndTime) ? presellEndTime.format('YYYY-MM-DD HH:mm:ss') : null,

    };

    if (this.parent.pageFormInfoStore.afterSaleEnabled) {
      Object.assign(platformExtends, supply7dayReturn);
    }
    delete platformExtends.deliveryMode;// 删除发货模式字段
    this.parent.listingTimeMode = listingTimeMode;
    params.goodsBase = {
      goodsName,
      goodsCategoryFullName: categoryName.replace(/>/g, ','),
      goodsCategoryId: categoryId,
      goodsCategoryIds: categoryIds,
    };
    params.platformExtends = platformExtends;

    // 面料成分
    const materialProps = this.parent.baseinfoStore.materialStore.getMaterialLists();
    params.platformExtends.materialProps = materialProps;
  
    const goodsPicVo = {
      mainPics: this.parent.mainImgsStore.mainPics.map((item) => item.url).filter((v) => v),
      detailPics: this.parent.productInfoStore.imgs.map((item) => item.url).filter((v) => v),
    };

    // 处理视频
    if (this.parent.singleUploadImgStore.videoFileList?.length > 0) {
      const { url, id } = this.parent.singleUploadImgStore.videoFileList[0];
      Object.assign(goodsPicVo, {
        videoUrl: url,
        videoManageId: id,
      });
    }

    params.goodsPicVo = goodsPicVo;
    const checkedColorList = toJS(this.parent.sizeColorStore.colorList).filter((item) => item.checked);
    const { colorPropName } = this.parent.sizeColorStore;
    const goodsPropPicVos = checkedColorList.map((item) => ({
      url: item.url,
      properties: `${colorPropName}:${item.name}`,
      smallPicUrl: '',
    }));
    params.goodsPropPicVos = goodsPropPicVos;

    const goodsSkuVos = toJS(this.parent.sizeColorStore.goodsSkuList);

    params.goodsProps = goodsProps;
    params.goodsSkuSetOnShelfRequestList = goodsSkuVos;
    
    return params;
  };

  // 保存
  @action public saveInfo = (): any => {
    if (!this.parent.sizeColorStore.saveValidate()) {
      scrollToAnchor('saleInfo');
      return;
    }
    this.saveInfoFn();
  };

  @action public saveInfoFn = async() => {
    this.parent.loading = true;
    const params = await this.releaseParams('save');
    request<BaseData<any>>({
      url: api.saveDouyin,
      method: 'post',
      data: { ...params },
    }).then((res) => {
      message.success('上架信息保存成功');
      this.parent.loading = false;
    })
      .catch((err) => {
        this.parent.loading = false;
      });
  };

  // 提交
  @action public submitInfo = async(): Promise<void> => {
    this.parent.loading = true;
    try {
      if (!this.parent.sizeColorStore.saveValidate()) {
        scrollToAnchor('saleInfo');
        return;
      }
      await this.parent.anchorStore.getIncomplete();

      const allTitles = this.parent.anchorStore.allTitles;
      const item = Object.keys(allTitles);
      for (let i = 0; i < Object.keys(allTitles).length; i++) {
        if (allTitles[item[i]].hasFilled < allTitles[item[i]].requiredNum) {
          scrollToAnchor(allTitles[item[i]].id);
          return;
        }
      }

      const params = await this.releaseParams('submit');
      this.submitParams = params;
      await this.submitInfoFn();
    } catch (e) {
      console.log(e);
    } finally {
      this.parent.loading = false;
    }
  };

  @action public submitInfoFn = () => {
    this.parent.submitResult = 'inProgress';
    this.parent.visibleSubmitResult = true;

    request<BaseData<any>>({
      url: api.submitDouyin,
      method: 'post',
      data: { ...this.submitParams },
      timeout: 60000,
    }).then((res) => {
      this.submitParams = {};

      // this.parent.parent.onPutAwayCallback(this.parent.shopId);
      this.parent.submitResult = 'Successful';
      const { platformId, shopName } = res.data;
      this.parent.detailId = platformId;
      this.parent.shopName = shopName;
    })
      .catch((e) => {
        message.destroy();
        this.parent.submitResult = 'Failed';
        this.parent.errorMessage = escape2Html(e.data?.info || '');
      });
  };

  @action public writeBackOtherInfo = (platformExtends) => {
    const { presellEndTime } = platformExtends;
    const { deliveryInfoFormRef, serviceInfoFormRef } = this.parent.pageFormInfoStore;
    if (presellEndTime) {
      platformExtends.presellEndTime = moment(presellEndTime, 'YYYY-MM-DD HH:mm:ss');
    }

    // 上架时间
    platformExtends.listingTimeMode = platformExtends.commit === false ? 2 : 0;

    // 运费模版
    platformExtends.freightId = platformExtends.freightId || this.parent.pageFormInfoStore.freightTemplateList[0].value;

    // 七天无理由
    platformExtends.supply7dayReturn = platformExtends.supply7dayReturn ?? '7-1';

    // 现货发货时间
    platformExtends.deliveryDelayDay = platformExtends.deliveryDelayDay || 9999;

    // 订单库存计数
    platformExtends.reduceType = platformExtends.reduceType ?? 1;
    deliveryInfoFormRef.current.setFieldsValue(platformExtends);
    serviceInfoFormRef.current.setFieldsValue(platformExtends);
    Object.assign(this.parent.pageFormInfoStore, platformExtends);
  };
}

export default Store;

