import { CloseCircleFilled, PlusOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import React from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import deleteIcon from '../../../../assets/images/deleteIcon.png';
import styles from './index.less';

// 图片展示卡片
const SortableItem = SortableElement(({ src, sortIndex, store, el }) => {
  const { onClickDelete } = store;
  return (
    <div
      className="publishGoodsPicture"
      style={{ marginBottom: 12 }}
    >
      <img
        alt=""
        className="img"
        src={src}
      />
      <img
        className="delete"
        onMouseDown={(e) => {
          onClickDelete(el.imgId);
          e.stopPropagation();
        }}
        src={deleteIcon}
      />
      {
        (store.type === 'mainPics' && sortIndex < 5) ? (
          <span className="coverIcon">
            默认主图
          </span>
        ) : ''
      }
    </div>
  );
});

const SortableList = SortableContainer(observer(({ items, store }) => {
  return (
    <div className={styles.mainPictures}>
      {items && items.map((el, index) => {
        return el && el.url && (
          <SortableItem
            el={el}
            index={index}
            key={`item-${el.imgId}`}
            sortIndex={index}
            src={el.url}
            store={store}
          />
        );
      }
      )}
      {store.type === 'productInfo' && (
        <div
          className={styles.singleUploadImgWrap}
          onClick={() => {
            store.setType('productInfoImg');
          }}
        >
          <div className={styles.plusIcon}>
            <PlusOutlined style={{ fontSize: 20 }}/>
          </div>
          <div>
            上传图片
          </div>
        </div>
      )}
    </div>
  );
}));

export default SortableList;
