import { Row, Col } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { MainImgs, ProductInfo, SingleUploadVideo } from '../../../../shelvesBase';
import styles from './index.less';

export const ImgTextInfo: React.FC<{ singleUploadImgStore; mainImgsStore; productInfoStore; }> = observer((props) => {
  return (
    <Row>
      <Col
        className={styles.colTitle}
        id="mainImgs"
        span={3}
      >
        <span className={styles.red}>
          *
        </span>
        商品主图：
      </Col>
      <Col
        className={styles.colContent}
        span={21}
      >
        <MainImgs
          singleUploadImgStore={props.singleUploadImgStore}
          store={props.mainImgsStore}
        />
      </Col>
      <Col
        className={styles.colTitle}
        span={3}
      >
        主图视频：
      </Col>
      <Col
        className={styles.colContent}
        span={21}
      >
        <div className={styles.videoTip}>
          {'仅支持mp4格式上传，大小100MB以内，宽高比1:1视频（像素建议不低于600px）,时长<=60s,建议30s内短视频最佳（可优先在商品等推荐频道展现）'}
          <a onClick={() => {
            window.open('https://school.jinritemai.com/doudian/web/article/113410?from=shop_default');
          }}
          >
            点击了解
          </a>
        </div>
        <SingleUploadVideo
          deleteVideo={() => {
            props.singleUploadImgStore.deleteImg('videoManage');
          }}
          fileList={props.singleUploadImgStore.videoFileList}
          selectVideo={() => {
            props.singleUploadImgStore.selectImg('videoManage');
          }}
          type="videoManage"
        />
      </Col>
      <Col
        className={styles.colTitle}
        id="mainImgs"
        span={3}
      >
        <span className={styles.red}>
          *
        </span>
        商品详情：
      </Col>
      <Col
        className={styles.colContent}
        span={21}
      >
        <ProductInfo
          singleUploadImgStore={props.singleUploadImgStore}
          store={props.productInfoStore}
        />
      </Col>
    </Row>
  );
});

