import { Col, Form, Input, Select, Spin } from 'antd';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { formLayout, ContentWrap } from '../../../../shelvesBase';
import styles from './index.less';
import { MaterialProps } from '../../../../../pages/shelvesNew/components';
import type Store from './store';

const { Item } = Form;
const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12 },
};

const payTypeList = [
  {
    label: '货到付款',
    value: 0,
  },
  {
    label: '在线支付',
    value: 1,
  },
  {
    label: '货到付款 + 在线支付',
    value: 2,
  },
];

const BaseInfo = observer((props) => {
  const { store: { baseinfoFormRef, changeInput, calcStrLen, blurInput, initialValues, goodsProps, initialValues: { goodsName, recommendRemark, payType }}} = props;
  return (
    <ContentWrap
      id="baseinfo"
      text="基本信息"
    >
      <Form
        initialValues={
          initialValues
        }
        ref={baseinfoFormRef}
        {...formLayout}
      >
        <Item
          label="商品标题"
          required
        >
          <div className={styles.itemWrap}>
            <Item
              name="goodsName"
              noStyle
              rules={
                [
                  {
                    required: true,
                    message: '请输入商品标题',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if ((!value || (value && calcStrLen(value) > 15 && calcStrLen(value) < 61))) {
                        return Promise.resolve();
                      }
                      return Promise.reject('商品标题限16-60个字符');
                    },
                  }),
                ]
              }
            >
              <Input
                onChange={(e) => {
                  changeInput(e, 'goodsName', 60);
                }}
                placeholder="请输入商品标题，限16-60个字符"
              />
            </Item>
          </div>
        </Item>
        <Item
          label="推荐语"
        >
          <div className={styles.itemWrap}>
            <Item
              name="recommendRemark"
              noStyle
              rules={
                [
                  {
                    required: false,
                    message: '请输入推荐语',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if ((!value || (value && calcStrLen(value) > 15 && calcStrLen(value) < 101))) {
                        return Promise.resolve();
                      }
                      return Promise.reject('推荐语限16-100个字符');
                    },
                  }),
                ]
              }
            >
              <Input
                onChange={(e) => {
                  changeInput(e, 'recommendRemark', 100);
                }}
                placeholder="请输入推荐语，限16-100个字符"
              />
            </Item>
          </div>
        </Item>
        <Item
          label="支付方式"
          name="payType"
          rules={
            [
              {
                required: true,
                message: '请选择支付方式',
              },
            ]
          }
        >
          <Select
            style={{ width: 330 }}
          >
            {
              payTypeList && payTypeList.map((item) => (
                <Select.Option
                  key={item.value}
                  value={item.value}
                >
                  {item.label}
                </Select.Option>
              ))
            }
          </Select>
        </Item>
        {
          goodsProps && goodsProps.length > 0 && (
            <Item label="商品属性">
              <div className={`${styles.itemWrap} ${styles.itemBack}`}>
                {/* 材质成分 */}
                {
                  props.store.hasMaterialProps ? (
                    <MaterialProps
                      inputNumberCustom
                      store={props.store.materialStore}
                    />
                  ) : null
                }

                {goodsProps && goodsProps.map((item, index) => {
                  // ... 在此处不同布局的判断
                  const key = item.id;
                  return (
                    <Col
                      className={item.className || ''}
                      key={key}
                      span={item.colspan || 12}
                    >
                      <Item
                        {...(item.itemLayout || formItemLayout)}
                        initialValue={item.type === 'INPUT' ? item.value : (item.type === 'MULTICHECK' ? (item.value ? item.value.split(',') : undefined) : (item.value || undefined))}
                        label={item.name}
                        name={key}
                        normalize={(value, prevValue, allValues) => {
                          if (item.type === 'MULTICHECK') {
                            if (typeof value === 'string') {
                              return value.split(',');
                            }
                          }
                          return value;
                        }}
                        rules={[
                          item.rules || {
                            required: item.required,
                            message: item.message,
                          },
                        ]}
                      >
                        {item.type === 'INPUT' ? (
                          <Input
                            autoComplete="off"
                            className={item.className || ''}
                            id={key}
                            placeholder={item.placeholder || '请输入'}
                          />
                        ) : (
                          <Select
                            allowClear={item.type !== 'INPUT'}
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            mode={item.type === 'MULTICHECK' ? 'multiple' : null}
                            notFoundContent={item.fetching ? <Spin size="small"/> : '无数据'}
                            placeholder="请选择"
                            showSearch
                          >
                            {item.checkOptions && item.checkOptions.map((v) => {
                              return (
                                <Option
                                  key={v.key}
                                  value={v.key}
                                >
                                  {v.value}
                                </Option>
                              );
                            })}
                          </Select>
                        )}
                      </Item>
                    </Col>
                  );
                })}
              </div>
            </Item>
          )
        }
        <SizeTemplates store={props.store}/>
      </Form>

    </ContentWrap>
  );
});

export default BaseInfo;

const SizeTemplates: React.FC<{ store: Store; }> = observer((props) => {
  const { sizeTemplateOptions } = props.store;
  return (
    <Item
      label="尺码模版"
    >
      <Item
        name="sizeInfoTemplateId"
        noStyle
      >
        <Select
          allowClear
          filterOption={(input, option) => {
            return option.props.label.includes(input);
          }}
          notFoundContent="无数据"
          options={sizeTemplateOptions}
          placeholder="请选择尺码模版"
          showSearch
          style={{ width: '180px' }}
        />
      </Item>
      <a
        className={styles.manageSize}
        onClick={() => {
          window.open('https://fxg.jinritemai.com/ffa/g/size-chart/manage?btm_ppre=a2427.b4745.c2483.route_3_1&btm_pre=a2427.b4745.c0.d0&btm_show_id=39d06835-a385-481d-af91-b3f960b32bac');
        }}
      >
        管理尺码模版
      </a>
    </Item>
  );
});

