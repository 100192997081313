import { Input, Checkbox, Row, Col } from 'antd';
import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import { AddIcon, ExpandCollapseButton } from '../components';
import styles from './index.less';

export const SizeInfo: React.FC<{ sizeColorStore; }> = observer((props) => {
  const { sizeList, addSizeDisplay, editSize, addSizeInput, handleSizeCheckChange, forbidCustomSize, showSizeCollapse, first10SizeOption, sizeExpand, toggleExpandSize } = props.sizeColorStore;
  
  // 是否只展示前10个尺码（选项数目>10项，并且收起）
  const displaySizeList = useMemo(() => {
    return showSizeCollapse && !sizeExpand ? first10SizeOption : sizeList;
  }, [
    showSizeCollapse,
    sizeExpand,
    sizeList,
  ]);
  return (
    <Row>
      <Col
        className={styles.colContent}
        span={3}
      >
        <span className={styles.requiredTip}>
          *
        </span>
        尺码
        <span className={styles.colMao}>
          :
        </span>
      </Col>
      <Col span={21}>
        <div className={styles.sizeWrapper}>
          {
            displaySizeList?.map((item, index) => (
              <div
                className={styles.eachSize}
                key={item.key}
              >
                <Checkbox
                  checked={item.checked}
                  onChange={(e) => {
                    handleSizeCheckChange(index, e.target.checked);
                  }}
                />
                {
                  item.editStatus ? (
                    <SizeInput
                      index={index}
                      sizeColorStore={props.sizeColorStore}
                    />
                  ) : (
                    <div
                      className={styles.checkText}
                      onClick={() => {
                        editSize(index, item.name);
                      }}
                    >
                      {item.name}
                    </div>
                  )
                }
              </div>
            ))
          }
          {
            addSizeDisplay && (
              <div
                className={styles.eachSize}
              >
                <Checkbox checked/>
                <SizeInput sizeColorStore={props.sizeColorStore}/>
              </div>
            )
          }
          {/* 添加按钮 */}
          {/* 显示条件：不禁止自定义尺码 (选项数目 < 10 || sizeExpand为true}*/}
          {
            !forbidCustomSize && (!showSizeCollapse || sizeExpand) && (
              <div className={styles.eachSize}>
                <AddIcon onClick={addSizeInput}/>
              </div>
            )
          }
          {/* 展开收起按钮 */}
          {
            showSizeCollapse && (
              <ExpandCollapseButton
                flag={sizeExpand}
                handleClick={() => {
                  toggleExpandSize(!sizeExpand);
                }}
                name="尺码"
              />
            )
          }
        </div>
      </Col>
    </Row>
  );
});

// 尺码输入框
const SizeInput: React.FC<{ sizeColorStore; index?: number; }> = observer((props) => {
  const { inputValue, onInputValueChange, onSizeInput } = props.sizeColorStore;
  const { index } = props;
  return (
    <Input
      autoFocus
      className={styles.colorSizeInput}
      onBlur={() => {
        onSizeInput(index);
      }}
      onChange={(e) => {
        onInputValueChange(e.target.value);
      }}
      onPressEnter={() => {
        onSizeInput(index);
      }}
      value={inputValue}
    />
  );
});
